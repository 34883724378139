import React from "react";
import DrawProfileContent from "./DrawProfileContent";
import { usePreGame } from "../../../providers/preGame";
import { useDrawProfile } from "./hooks/useDrawProfile";
import { usePreGameHook } from "../PreGame/hooks/usePreGameHook";

export default function DrawProfile(): JSX.Element {
  const { profile, isDrawingProfile } = usePreGame();
  const { confirmProfile } = useDrawProfile();
    // eslint-disable-next-line no-empty-pattern
    const {} = usePreGameHook();

  return (
    <DrawProfileContent
      profile={profile}
      isDrawingProfile={isDrawingProfile}
      confirmProfile={confirmProfile}
    />
  );
}
