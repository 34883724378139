import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import React, { ReactElement } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Theme } from "../../../DTOs/Theme";
import { gameTexts } from "../utils/GameTexts";
import GenericLoader from "../../../shared/Loaders/GenericLoader";

interface ThemeCardProps {
  theme: Theme | null;
  isDrawingTheme?: boolean;
}

function ThemeCard({ theme, isDrawingTheme }: ThemeCardProps): ReactElement {
  return (
    <div className="bg-yellow rounded-3xl p-6 max-w-md shadow-lg text-center mb-4 my-8 min-w-72">
      {isDrawingTheme ? (
        <GenericLoader screen={false} />
      ) : theme ? (
        <>
          <h2 className="text-body-regular font-medium">o tema é:</h2>
          <h1 className="text-heading-1 font-semibold mt-4 mb-6">{theme?.name}</h1>
          <Accordion defaultExpanded className="rounded-3xl">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="flex flex-col"
              classes={{ contentGutters: "mb-0" }}
            >
              <p className="text-body-medium-2">{theme?.questionToDebate}</p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="rounded-3xl p-4">
                <p className="text-body-regular-2">{theme?.proArgument}</p>

                <Divider variant="middle" className="bg-yellow m-5" />

                <p className="text-body-regular-2">{theme?.contraArgument}</p>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <div className="text-black text-body-regular font-medium">
          {gameTexts.DRAW_THEMES_FAILED}
        </div>
      )}
    </div>
  );
}

export default ThemeCard;
