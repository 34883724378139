import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../../providers/socket";
import { usePreGame } from "../../../../providers/preGame";
import { MATCH_CHANGES, STOP_VOTING } from "../../../../shared/utils/SocketEvents";
import { useCallback, useEffect } from "react";
import { useGame } from "../../../../providers/game";
import { MatchResult } from "../../../../DTOs/Result";
import { GameRoutes } from "../../../../routes/SystemRoutes";

export const useWaitingVotes = () => {
  const { socket } = useSocket();
  const { matchId, setInstruction } = usePreGame();
  const { setMatchResult, stopVoting } = useGame();
  const navigate = useNavigate();

  const handleGoToScore = useCallback((matchResult: MatchResult[]) => {
    setMatchResult(matchResult);
    navigate(GameRoutes.MatchResult);
    setInstruction("");
  }, [navigate, setInstruction, setMatchResult]);

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error && matchId === data.matchId) {
          switch (data.type) {
            case STOP_VOTING:
              handleGoToScore(data.matchResult);
              break;
          }
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [socket, handleGoToScore, matchId]);

  return { stopVoting };
};
