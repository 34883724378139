import React, { ReactElement, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreButtonContent from "./MoreButtonContent";
import GenericModal from "../../Modals/GenericModal";
import AdminActionsModal from "./components/ModalContents/AdminActionsModalContent";
import {
  buttonTexts,
  CONFIRM_LEAVE_MATCH,
  LEAVE_MATCH,
  MOVE_ADMIN,
  MOVE_ADMIN_LEAVE_MATCH,
  REMOVE_PLAYER
} from "./utils/MoreButtonActionTexts";
import ConfirmModal from "../../Modals/ConfirmModal";
import { useAdminActionsButton } from "./hooks/useMoreButtonActions";

interface MoreButtonProps {
  color?: string;
}

const MoreButton: React.FC<MoreButtonProps> = ({ color = "bg-pink-500" }) => {
  const {
    handleConfirmMoveAdmin,
    open,
    setOpen,
    handleExitGame,
    handleRemovePlayers,
    handleMoveAdminAndLeave,
    playersIn,
    room,
    isLoading
  } = useAdminActionsButton();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [modalContent, setModalContent] = useState<ReactElement>(<></>);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const moveAdmin = () => {
    setModalContent(
      <AdminActionsModal
        handleClose={() => setOpen(false)}
        players={playersIn}
        handleConfirm={handleConfirmMoveAdmin}
        description={MOVE_ADMIN}
        buttonLabel={buttonTexts.MOVE_ADMIN}
      />
    );
    setOpen(true);
  };

  const removeParticipant = () => {
    setModalContent(
      <AdminActionsModal
        handleClose={() => setOpen(false)}
        players={playersIn}
        handleConfirm={handleRemovePlayers}
        description={REMOVE_PLAYER}
        buttonLabel={buttonTexts.REMOVE_PLAYER}
        checkIcons={false}
        allowMultipleSelection={true}
      />
    );
    setOpen(true);
  };

  const exitGameAdmin = () => {
    const roomHavePlayers = playersIn.length > 0;
    setModalContent(
      <AdminActionsModal
        handleClose={() => setOpen(false)}
        players={playersIn}
        handleConfirm={roomHavePlayers ? handleMoveAdminAndLeave : handleExitGame}
        description={roomHavePlayers ? MOVE_ADMIN_LEAVE_MATCH : LEAVE_MATCH}
        buttonLabel={roomHavePlayers ? buttonTexts.MOVE_ADMIN_LEAVE_MATCH : buttonTexts.LEAVE}
        enableActionButton={roomHavePlayers ? false : true}
      />
    );
    setOpen(true);
  };

  const moreButtonAdminActions = [
    { name: buttonTexts.MOVE_ADMIN, handleClick: () => moveAdmin() },
    { name: buttonTexts.REMOVE_PLAYER, handleClick: () => removeParticipant() },
    { name: buttonTexts.LEAVE_MATCH, handleClick: () => exitGameAdmin() }
  ];

  const exitGame = () => {
    setModalContent(
      <ConfirmModal
        handleClose={() => setOpen(false)}
        handleConfirm={handleExitGame}
        description={CONFIRM_LEAVE_MATCH}
      />
    );
    setOpen(true);
  };

  const moreButtonPlayerActions = [{ name: "Sair da Partida", handleClick: () => exitGame() }];

  const isAdmin = room?.player?.isAdmin;

  return (
    <>
      <button
        onClick={handleClick}
        className={`flex items-center justify-center w-9 h-9 rounded-full ${color} text-white border-[3px] border-white shadow-lg`}
        aria-label="more-options"
      >
        <MoreVertIcon sx={{ fontSize: "25px", margin: 0 }} />
        <MoreButtonContent
          handleClose={handleClose}
          anchorEl={anchorEl}
          moreButtonActions={isAdmin ? moreButtonAdminActions : moreButtonPlayerActions}
        />
      </button>
      <GenericModal
        handleClose={() => setOpen(false)}
        open={open}
        children={modalContent}
        isLoading={isLoading}
      />
    </>
  );
};

export default MoreButton;
