import { MatchResult } from "../DTOs/Result";
import * as ResultService from "../services/ResultService/ResultService";

export const getMatchResults = async (id: string): Promise<MatchResult[]> => {
  const response = await ResultService.getMatchResults(id);
  return response.data;
};

export const getGameResults = async (id: string): Promise<MatchResult[]> => {
  const response = await ResultService.getGameResults(id);
  return response.data;
};
