import { Profile } from "../DTOs/Profile";
import * as ProfileService from "../services/ProfileService/ProfileService";

export const getProfileById = async (id: string): Promise<Profile> => {
  const response = await ProfileService.getProfileById(id);
  return response.data;
};

export const getProfilesById = async (ids: number[]): Promise<Profile[]> => {
  const response = await ProfileService.getProfilesById({ ids });
  return response.data;
};
