import React, { useEffect, useState } from "react";
import GameResultContent from "./GameResultContent";
import { useGameRoom } from "../../providers/room";
import BaseLayout from "../../shared/components/BaseLayout";
import { useNavigate } from "react-router-dom";
import * as resultManager from "../../managers/ResultManager";
import { MatchResult } from "../../DTOs/Result";
import { useNotification } from "../../providers/notify";
import { errorMessages } from "../Game/utils/GameNotifications";
import { CommonRoutes } from "../../routes/SystemRoutes";

export default function GameResult(): JSX.Element {
  const { room } = useGameRoom();
  const { notifyError } = useNotification();
  const [gameResults, setGameResults] = useState<MatchResult[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchResults = async () => {
      if (room?.id === undefined) return;
      try {
        const results = await resultManager.getGameResults(room.id);
        results.forEach((result) => {
          result.primaryColor = "#E73088";
          result.secondaryColor = "#FFFFFF";
        });
        setGameResults(results);
      } catch (error) {
        notifyError(errorMessages.GET_MATCH_RESULTS_FAILED);
      } finally {
        setIsLoading(false);
      }
    };
    fetchResults();
  }, [room?.id, notifyError]);

  const handleGoBackToHome = () => {
    navigate(CommonRoutes.GameHome);
  };

  return (
    <BaseLayout>
      <GameResultContent
        gameResults={gameResults}
        room={room}
        handleGoBackToHome={handleGoBackToHome}
        isLoading={isLoading}
      />
    </BaseLayout>
  );
}
