import React from "react";
import { MatchResult } from "../../DTOs/Result";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { gameButtonTexts, gameTexts, gameTitles } from "../Game/utils/GameTexts";
import { CreateRoomResponse } from "../../DTOs/Player";
import Badge from "../../shared/components/Badge";
import ResultTable from "../../shared/components/GameComponents/ResultTable";

interface MatchResultContentProps {
  matchResult: MatchResult[];
  isAdmin: boolean;
  room: CreateRoomResponse | null;
  resultNumber: number;
  handleNewMatch: () => void;
  handleFinishGame: () => void;
}

const columns: { header: string; accessor: keyof MatchResult; style?: React.CSSProperties }[] = [
  { header: "perfil", accessor: "profileName" },
  { header: "jogador", accessor: "playerName" },
  { header: "pontos", accessor: "score", style: { textAlign: "right" } }
];

export default function MatchResultContent({
  matchResult,
  isAdmin,
  room,
  resultNumber,
  handleNewMatch,
  handleFinishGame
}: MatchResultContentProps): JSX.Element {
  return (
    <div className="flex flex-col w-full min-h-36 items-center">
      <div className="flex flex-row justify-center items-center my-6">
        <h4 className="text-body-bold text-white mr-4">{room?.player?.playerName}</h4>
        {room?.player.isAdmin ? <Badge text="admin" /> : <Badge text="player" />}
        <div className="bg-blue-500 text-white rounded-md p-1 ml-10">
          {gameTexts.RESULT.toLowerCase()}
        </div>
      </div>
      <p className="text-white text-body-semibold mb-6">
        {gameTexts.RESULT} {resultNumber > 0 ? resultNumber : ""}
      </p>
      <h1 className="text-heading-1 font-medium mb-6">{gameTitles.MATCH_RANKING}</h1>
      <ResultTable rows={matchResult} columns={columns} />
      {isAdmin && (
        <>
          <CustomButton size="large" className="w-11/12 my-4" onClick={handleNewMatch}>
            {gameButtonTexts.NEW_MATCH}
          </CustomButton>
          <CustomButton size="large" className="w-11/12 mb-4" onClick={handleFinishGame}>
            {gameButtonTexts.FINISH_GAME}
          </CustomButton>
        </>
      )}
    </div>
  );
}
