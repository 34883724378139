export enum ApiErrors {
  NotFound = "NOT_FOUND",
  Unauthorized = "UNAUTHORIZED",
  BadRequest = "BAD_REQUEST",
  InternalError = "INTERNAL_ERROR",
  Forbidden = "FORBIDDEN",
  USER_ALREADY_EXIST = "User already exists",
  INVALID_CREDENTIALS = "Email and/or Password invalids",
  PLAYER_ALREADY_EXISTS = "Player Already exists",
  SEED_NOT_EXISTS = "Seed not Exists",
  ROOM_NOT_ACTIVE = "Room is not active anymore, you cannot add a new player",
  ROOM_NOT_FOUND = "Room not found",
}
