import React, { ReactElement } from "react";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import logoQuePerfil from "../../assets/brand/logo-que-perfil-contorno.svg";
import background from "../../assets/brand/background-home.svg";
import { useNavigate } from "react-router-dom";
import { BLOG_PAGE, GO_TO_GAME, PROJECT_PAGE } from "../../shared/utils/ButtonTexts";
import { CommonRoutes } from "../../routes/SystemRoutes";
import { BLOG_LINK, PROJECT_LINK } from "../../shared/utils/RedirectLinks";

function HomeContent(): ReactElement {
  const navigate = useNavigate();
  return (
    <main
      style={{ backgroundImage: `url(${background})` }}
      className="flex flex-col bg-blue-100 items-center justify-around h-screen bg-cover bg-center"
    >
      <img src={logoQuePerfil} alt="logo-que-perfil" className="w-auto" />
      <div className="flex flex-col w-80 mdMax:w-11/12">
        <CustomButton
          textColor="white"
          size="large"
          customStyle={{ margin: 2 }}
          className="bg-pink-500 hover:bg-pink-600"
          onClick={() => navigate(CommonRoutes.GameHome)}
        >
          {GO_TO_GAME}
        </CustomButton>
      </div>
      <div className="flex flex-col w-80 mdMax:w-11/12">
        <CustomButton
          variant="contained"
          size="large"
          styleButton="secondary"
          customStyle={{ margin: 2 }}
          onClick={() => window.open(PROJECT_LINK, "_blank")}
        >
          {PROJECT_PAGE}
        </CustomButton>
        <CustomButton
          variant="contained"
          size="large"
          customStyle={{ margin: 2 }}
          styleButton="secondary"
          onClick={() => window.open(BLOG_LINK, "_blank")}
        >
          {BLOG_PAGE}
        </CustomButton>
      </div>
    </main>
  );
}

export default HomeContent;
