import React, { ReactElement } from "react";
import CustomButton from "../../../shared/components/Buttons/CustomButton";
import RoomPlayersManagerContent from "../../../shared/RoomPlayersManager";
import { Player } from "../../../DTOs/Player";
import { ProfilesRaffledContinue } from "../../../providers/preGame";
import { gameButtonTexts, gameTexts, gameTitles } from "../utils/GameTexts";

interface PreGameProps {
  notReadYet: Player[];
  alreadyRead: ProfilesRaffledContinue[];
  isAdmin: boolean;
  isDrawingProfile: boolean;
  startMatch: () => void;
}

function PreGameContent({
  alreadyRead,
  notReadYet,
  isAdmin,
  startMatch
}: PreGameProps): ReactElement {
  return (
    <div className="flex flex-col items-center w-full">
      <RoomPlayersManagerContent
        playersIn={alreadyRead}
        playersOut={notReadYet}
        playersInTitle={gameTitles.READ_PROFILE_ALREADY}
        playersInDescription={gameTexts.SELECTED_PROFILE}
        playersOutTitle={gameTitles.STILL_NOT_READ_PROFILE}
        playersOutDescription={gameTexts.NOT_SELECTED_PROFILE}
        isAdmin={isAdmin}
      />
      {isAdmin && (
        <CustomButton
          size="large"
          className="mb-4 w-11/12"
          onClick={() => startMatch()}
        >
          {gameButtonTexts.START_MATCH}
        </CustomButton>
      )}
    </div>
  );
}

export default PreGameContent;
