import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { StyledInput } from "./styles";

interface CustomFormInputProps {
  label?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  type?: string;
  error?: boolean;
  helperText?: string;
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CustomFormInput({
  label,
  placeholder,
  name,
  value,
  type = "text",
  error = false,
  helperText,
  autoComplete,
  onChange
}: CustomFormInputProps) {
  return (
    <>
      <InputLabel
        shrink
        htmlFor={`custom-input-${name}`}
        sx={{ fontFamily: "Dosis, sans-serif" }}
        className="text-white text-heading-bold"
      >
        {label}
      </InputLabel>
      <StyledInput
        error={error}
        placeholder={placeholder}
        size="medium"
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        id={`custom-input-${name}`}
        autoComplete={autoComplete}
      />
      <FormHelperText
        error={error}
        sx={{ fontFamily: "Dosis, sans-serif" }}
        className={error ? "text-red" : "text-white"}
      >
        {helperText}
      </FormHelperText>
    </>
  );
}
