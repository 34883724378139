export const CONFIRM_LEAVE_MATCH = "Deseja realmente sair da partida?";
export const CONFIRM_GO_TO_HOME = "Deseja realmente voltar para a tela inicial?";
export const MOVE_ADMIN = "Selecione para quem será o novo administrador da partida:";
export const MOVE_ADMIN_LEAVE_MATCH =
  "Se você sair agora vai perder o direito de administrador selecione um novo antes de ir:";
export const LEAVE_MATCH = "Se você sair agora a sala será encerrada.";
export const REMOVE_PLAYER = "Selecione o(s) jogador(es) que você gostaria de remover:";

// BUTTONS
export const buttonTexts = {
  MOVE_ADMIN: "Passar Administração",
  MOVE_ADMIN_LEAVE_MATCH: "Passar Administração e Sair",
  LEAVE: "Sair",
  LEAVE_MATCH: "Sair da Partida",
  REMOVE_PLAYER: "Remover Jogador"
};

// NOTIFICATIONS
export const messages = {
  MOVE_ADMIN_ERROR: "Erro ao mudar administrador!",
  MOVE_ADMIN_SUCCESS: "Novo administrador(a) definido com sucesso!",
  REMOVE_PLAYER_ERROR: "Erro ao remover jogador(es)!",
  REMOVE_PLAYER_SUCCESS: "Jogador(es) removido(s) com sucesso!"
};
