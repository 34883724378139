import React from "react";
import { MatchResult } from "../../DTOs/Result";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { gameButtonTexts, gameTexts, gameTitles } from "../Game/utils/GameTexts";
import { CreateRoomResponse } from "../../DTOs/Player";
import ResultTable from "../../shared/components/GameComponents/ResultTable";
import GenericLoader from "../../shared/Loaders/GenericLoader";

interface GameResultContentProps {
  gameResults: MatchResult[];
  room: CreateRoomResponse | null;
  handleGoBackToHome: () => void;
  isLoading?: boolean;
}

const columns: { header: string; accessor: keyof MatchResult; style?: React.CSSProperties }[] = [
  { header: "jogador", accessor: "playerName" },
  { header: "pontos", accessor: "score", style: { textAlign: "right" } }
];

export default function GameResultContent({
  gameResults,
  room,
  handleGoBackToHome,
  isLoading
}: GameResultContentProps): JSX.Element {
  return (
    <div className="flex flex-col w-full min-h-36 items-center">
      <div className="flex flex-row justify-center items-center my-6">
        <h4 className="text-body-bold text-white mr-4">{room?.player?.playerName}</h4>
        <div className="bg-white text-black font-semibold rounded-md p-1 ml-8">
          {gameTexts.RESULT.toLowerCase()}
        </div>
      </div>
      <h1 className="text-heading-1 font-medium mb-6">{gameTitles.GAME_RANKING}</h1>
      {gameResults.length > 0 ? (
        <>
          <ResultTable rows={gameResults} columns={columns} showTrophyIcon />
          <CustomButton size="large" className="w-11/12 my-4" onClick={handleGoBackToHome}>
            {gameButtonTexts.GO_BACK_TO_HOME}
          </CustomButton>
        </>
      ) : isLoading ? (
        <div className="bg-pink-500 rounded-3xl p-6 max-w-md shadow-lg text-center mb-4 my-8 min-w-72">
          <GenericLoader />
        </div>
      ) : (
        <div className="bg-pink-500 rounded-3xl p-6 max-w-md shadow-lg text-center mb-4 my-8 min-w-72">
          <p className="text-white text-body-regular font-medium">
            {gameTexts.GAME_RESULTS_FAILED}
          </p>
        </div>
      )}
    </div>
  );
}
