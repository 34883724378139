import React, { ReactElement } from "react";
import CustomButton from "../../../shared/components/Buttons/CustomButton";
import { gameButtonTexts } from "../utils/GameTexts";
import { GameProfile, Profile } from "../../../DTOs/Profile";
import { GuessOption, MatchProfile, ProfilesVoting } from "../../../DTOs/Match";
import ProfileSelect from "../components/ProfileSelect";

interface VotingContentProps {
  profilePlayers: GameProfile[] | null;
  options: GuessOption[];
  matchProfile: MatchProfile | null;
  handlePlayerSelect: (
    profileId: number,
    oldValue: string,
    selectedPlayerId: string,
    selectedPlayerName: string,
    key: string
  ) => void;
  renderProfileModal: (profile: Profile | undefined) => void;
  handleResetSelections: () => void;
  resetFields: boolean;
  sendVoting: () => void;
  profileSelections: { [key: string]: ProfilesVoting | null };
}

function VotingContent({
  profilePlayers,
  options,
  matchProfile,
  handlePlayerSelect,
  renderProfileModal,
  handleResetSelections,
  resetFields,
  sendVoting,
  profileSelections
}: VotingContentProps): ReactElement {
  return (
    <>
      <div className="grid grid-cols-3 mt-8">
        {profilePlayers?.map((e) => (
          <ProfileSelect
            key={e?.playerId + e.profile?.id}
            profile={e?.profile}
            options={options}
            itIsMe={
              matchProfile?.profileId === e?.profile?.id && matchProfile?.playerId === e?.playerId
            }
            handlePlayerSelect={handlePlayerSelect}
            onClick={() => renderProfileModal(e?.profile)}
            resetFields={resetFields}
            refKey={e?.playerId + e.profile?.id}
            selectValue={profileSelections[e?.playerId + e.profile?.id]?.playerId}
          />
        ))}
      </div>
      <div className="flex flex-col w-full items-center px-6">
        <CustomButton
          size="large"
          styleButton="secondary"
          className="my-4 w-11/12"
          onClick={handleResetSelections}
        >
          {gameButtonTexts.CLEAN_NAMES}
        </CustomButton>
        <CustomButton
          size="large"
          className="mb-4 w-11/12"
          onClick={sendVoting}
          disabled={Object.keys(profileSelections).length + 1 !== profilePlayers?.length}
        >
          {gameButtonTexts.CONFIRM_VOTES}
        </CustomButton>
      </div>
    </>
  );
}

export default VotingContent;
