import React, { useState } from "react";
import DeliberationContent from "./DeliberationContent";
import { usePreGame } from "../../../providers/preGame";
import { useDeliberation } from "./hooks/useDeliberation";
import { useGame } from "../../../providers/game";

export default function Deliberation(): JSX.Element {
  const { theme, isAdmin, matchProfile } = usePreGame();
  const { profilePlayers, guessOptions, handlePlayerSelect, resetSelections, loadingProfiles } = useDeliberation();
  const { renderProfileModal, seconds, handleFinishMatch } = useGame();
  const [resetFields, setResetFields] = useState(false);

  const handleResetSelections = () => {
    setResetFields(!resetFields);
    resetSelections();
  };

  return (
    <DeliberationContent
      theme={theme}
      isAdmin={isAdmin}
      profilePlayers={profilePlayers}
      options={guessOptions}
      matchProfile={matchProfile}
      handlePlayerSelect={handlePlayerSelect}
      renderProfileModal={renderProfileModal}
      handleResetSelections={handleResetSelections}
      resetFields={resetFields}
      seconds={seconds}
      handleFinishMatch={handleFinishMatch}
      loadingProfiles={loadingProfiles}
    />
  );
}
