import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import RoomOptions from "../pages/RoomOptions";
import PrivateRoute from "./PrivateRoute";
import EnterRoom from "../pages/EnterRoom";
import Lobby from "../pages/Lobby";
import Header from "../shared/components/Header";
import CommonLayout from "../pages/Game";
import DrawTheme from "../pages/Game/DrawTheme";
import DrawProfile from "../pages/Game/DrawProfile";
import PreGame from "../pages/Game/PreGame";
import Deliberation from "../pages/Game/Deliberation";
import Voting from "../pages/Game/Voting";
import WaitingVotes from "../pages/Game/WaitingVotes";
import MatchResult from "../pages/MatchResult";
import GameResult from "../pages/GameResult";
import GameHome from "../pages/GameHome";
import { CommonRoutes, GameRoutes } from "./SystemRoutes";

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== CommonRoutes.Home && location.pathname !== CommonRoutes.GameHome && (
        <Header />
      )}
      <Routes>
        <Route path={CommonRoutes.Home} element={<Home />} />
        <Route path={CommonRoutes.GameHome} element={<GameHome />} />
        <Route path={CommonRoutes.NotFound} element={<NotFound />} />
        <Route path={CommonRoutes.Login} element={<Login />} />
        <Route path={CommonRoutes.Register} element={<Register />} />
        <Route path={CommonRoutes.RoomOptions} element={<RoomOptions />} />
        <Route path={CommonRoutes.EnterRoom} element={<EnterRoom />} />

        {/* Rotas Privadas */}
        <Route element={<PrivateRoute allowedRole="both" />}>
          <Route path={GameRoutes.Lobby} element={<Lobby />} />
          <Route path={GameRoutes.MatchResult} element={<MatchResult />} />
          <Route path={GameRoutes.GameResult} element={<GameResult />} />
          <Route path="/" element={<CommonLayout />}>
            <Route path={GameRoutes.DrawTheme} element={<DrawTheme />} />
            <Route path={GameRoutes.DrawProfile} element={<DrawProfile />} />
            <Route path={GameRoutes.PreGame} element={<PreGame />} />
            <Route path={GameRoutes.Deliberation} element={<Deliberation />} />
            <Route path={GameRoutes.Voting} element={<Voting />} />
            <Route path={GameRoutes.WaitingVotes} element={<WaitingVotes />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

const RoutesSystem: React.FC = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default RoutesSystem;
