import React from "react";
import DrawThemeContent from "./DrawThemeContent";
import { usePreGame } from "../../../providers/preGame";
import { useGameRoom } from "../../../providers/room";
import { useDrawTheme } from "./hooks/useDrawTheme";

export default function DrawTheme(): JSX.Element {
  const { room } = useGameRoom();
  const { drawTheme, confirmTheme, isDrawingTheme } = useDrawTheme();
  const { theme, isAdmin } = usePreGame();

  return (
    <DrawThemeContent
      drawTheme={drawTheme}
      confirmTheme={confirmTheme}
      currentTheme={theme}
      room={room}
      isDrawingTheme={isDrawingTheme}
      isAdmin={isAdmin}
    />
  );
}
