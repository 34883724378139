export const successMessages = {
  USER_CREATED_SUCCESSFULLY: "Usuário criado com sucesso!",
  USER_UPDATED_SUCCESSFULLY: "Usuário atualizado com sucesso!"
};

export const errorMessages = {
  FILL_IN_ALL_FIELDS: "Preencha todos os campos!",
  GENERIC_ERROR: "Ops... algo deu errado, tente novamente mais tarde!",
  GOOGLE_LOGIN_FAILED: "Erro ao realizar login com o google, tente novamente mais tarde!",
  INVALID_CREDENTIALS: "Credenciais inválidas!",
  INVALID_PLAYER_NAME: "Insira um nome de jogador válido!",
  INVALID_SEED: "O código informado não exite!",
  INVALID_VALUE: "Valor inválido!",
  JOIN_ROOM_FAILED: "Erro ao entrar na sala, tente novamente mais tarde!",
  LOGIN_FAILED: "Erro ao realizar login, tente novamente mais tarde!",
  MIN_PASSWORD_DIGITS: "A senha deve ter pelo menos 6 caracteres!",
  PASSWORDS_DO_NOT_MATCH: "As senhas não coincidem!",
  PLAYER_CREATION_FAILED: "Erro ao criar jogador, tente novamente mais tarde!",
  PLAYER_NAME_ALREADY_EXISTS: "Já existe um jogador nesta sala com esse nome. Por favor, escolha outro!",
  ROOM_NOT_ACTIVE: "Esta sala não está mais ativa, aguarde o término da rodada!",
  ROOM_CREATION_FAILED: "Erro ao criar sala, tente novamente mais tarde!",
  USER_ALREADY_EXISTS: "E-mail já cadastrado!",
  USER_CREATION_FAILED: "Erro ao criar usuário, tente novamente mais tarde!",
  USER_UPDATE_FAILED: "Erro ao atualizar usuário, tente novamente mais tarde!"
};

export const warningMessages = {
  USER_CREATION_FAILED: "Erro ao criar usuário, tente novamente mais tarde!"
};

export const infoMessages = {
  LOGIN_TO_CREATE_ROOM: "Faça login para criar uma sala!",
  PLAYER_REMOVED_BY_ADMIN: "Você foi removido da sala pelo administrador!",
  PLAYER_IS_NOW_ADMIN: "Você agora é o administrador da sala!"
};
