import React, { useEffect, useState } from "react";
import LoginContent from "./LoginContent";
import { useNotification } from "../../providers/notify";
import { loginInputs } from "./utils/LoginInputs";
import { errorMessages } from "../../shared/utils/NotifyMessages";
import { LoginRequest } from "../../services/SessionService/types";
import * as SessionManager from "../../managers/SessionManager";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { ApiErrors } from "../../shared/utils/Errors";
import { CommonRoutes } from "../../routes/SystemRoutes";

export default function Login(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAuthenticated, setSession } = useAuth();
  const navigate = useNavigate();
  const { notifyError } = useNotification();
  const [formValues, setFormValues] = useState(
    loginInputs.reduce((acc, input) => {
      acc[input.name] = "";
      return acc;
    }, {} as Record<string, string>)
  );
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isAuthenticated) navigate(CommonRoutes.RoomOptions);
  }, [isAuthenticated, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
      password: ""
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const user: LoginRequest = {
      email: formValues.email,
      password: formValues.password
    };

    try {
      const response = await SessionManager.login(user);
      setSession(response);
      navigate(CommonRoutes.RoomOptions);
    } catch (err: any) {
      if (err?.data?.message === ApiErrors.INVALID_CREDENTIALS) {
        notifyError(errorMessages.INVALID_CREDENTIALS);
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: errorMessages.INVALID_CREDENTIALS,
          password: errorMessages.INVALID_CREDENTIALS
        }));
      } else notifyError(errorMessages.LOGIN_FAILED);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <LoginContent
      formValues={formValues}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      errors={errors}
      isLoading={isLoading}
    />
  );
}
