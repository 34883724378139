import React, { ReactElement } from "react";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import logoQuePerfil from "../../assets/brand/logo-que-perfil-contorno.svg";
import background from "../../assets/brand/background-home.svg";
import { useNavigate } from "react-router-dom";
import { KNOW_HOW_TO_PLAY, PLAY_AS_GUEST, PLAY_WITH_LOGIN } from "../../shared/utils/ButtonTexts";
import { CREDITS_AND_CONTACT } from "../../shared/utils/LinkTexts";
import { CommonRoutes } from "../../routes/SystemRoutes";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "../../shared/components/Buttons/IconButton";

function GameHomeContent(): ReactElement {
  const navigate = useNavigate();
  return (
    <main
      style={{ backgroundImage: `url(${background})` }}
      className="flex flex-col bg-blue-100 items-center justify-around h-screen bg-cover bg-center"
    >
      <IconButton
      icon={<HomeIcon sx={{ fontSize: "15.5px" }} />}
      tooltipText="Início"
      handleClick={() => navigate(CommonRoutes.Home)}
      />
      <img src={logoQuePerfil} alt="logo-que-perfil" className="w-auto" />
      <div className="flex flex-col w-80 mdMax:w-11/12">
      <CustomButton
        textColor="white"
        size="large"
        customStyle={{ margin: 2 }}
        className="bg-pink-500 hover:bg-pink-600"
        onClick={() => navigate(CommonRoutes.Login)}
      >
        {PLAY_WITH_LOGIN}
      </CustomButton>
      <CustomButton
        textColor="white"
        size="large"
        customStyle={{ margin: 2 }}
        className="bg-pink-500 hover:bg-pink-600"
        onClick={() => navigate(CommonRoutes.RoomOptions)}
      >
        {PLAY_AS_GUEST}
      </CustomButton>
      <CustomButton
        textColor="white"
        size="large"
        customStyle={{ margin: 2 }}
        styleButton="secondary"
      >
        {KNOW_HOW_TO_PLAY}
      </CustomButton>
      </div>
      <p className="flex font-bold bg-blue-100 rounded-lg">{CREDITS_AND_CONTACT}</p>
    </main>
  );
}

export default GameHomeContent;
