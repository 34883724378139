import React, { ReactElement } from "react";
import { Profile } from "../../../DTOs/Profile";
import ProfileCard from "../components/ProfileCard";
import CustomButton from "../../../shared/components/Buttons/CustomButton";
import { gameButtonTexts } from "../utils/GameTexts";

interface DrawProfileProps {
  confirmProfile: () => void;
  isDrawingProfile: boolean;
  profile: Profile | null;
}

function DrawProfileContent({
  confirmProfile,
  isDrawingProfile,
  profile
}: DrawProfileProps): ReactElement {
  return (
    <div className="flex flex-col w-full items-center">
      <ProfileCard profile={profile} isDrawingProfile={isDrawingProfile} />
      <CustomButton
        size="large"
        className="mb-4 w-11/12"
        onClick={() => confirmProfile()}
        disabled={isDrawingProfile}
      >
        {gameButtonTexts.CONTINUE}
      </CustomButton>
    </div>
  );
}

export default DrawProfileContent;
