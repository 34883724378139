import React, { ReactElement } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import BlockIcon from "@mui/icons-material/Block";
import Badge from "../components/Badge";
import { Player } from "../../DTOs/Player";
import { ProfilesRaffledContinue } from "../../providers/preGame";
import { ProfilesVoting } from "../../DTOs/Match";

interface RoomPlayersManagerContentProps {
  playersIn: Player[] | ProfilesRaffledContinue[] | ProfilesVoting[];
  playersOut: Player[] | ProfilesVoting[];
  playersInTitle?: string;
  playersOutTitle?: string;
  playersInDescription?: string;
  playersOutDescription?: string;
  isAdmin?: boolean;
}

const PlayerInList: React.FC<{
  player: Player | ProfilesRaffledContinue | ProfilesVoting | Partial<Player>;
  description?: string;
}> = ({ player, description }) => (
  <div className="flex flex-row items-center mb-1">
    <TaskAltIcon sx={{ color: "#2DC071" }} />
    <p>
      <span className="text-green-200 text-body-semibold ml-2">{player.playerName}</span>{" "}
      <span className="text-green-200 text-body-regular">{description}</span>
    </p>
    {player.isAdmin && <Badge text="admin" className="ml-4" />}
  </div>
);

const PlayerOutList: React.FC<{ player: Player | ProfilesVoting; description?: string }> = ({
  player,
  description
}) => (
  <div className="flex flex-row items-center mb-1">
    <BlockIcon sx={{ color: "#E0232E" }} />
    <p>
      <span className="text-red-500 text-body-semibold ml-2">{player.playerName}</span>{" "}
      <span className="text-red-500 text-body-regular">{description}</span>
    </p>
  </div>
);

function RoomPlayersManagerContent({
  playersIn,
  playersOut,
  playersInTitle,
  playersOutTitle,
  playersInDescription,
  playersOutDescription,
  isAdmin
}: RoomPlayersManagerContentProps): ReactElement {
  const me = {
    playerName: "Você",
    isAdmin: isAdmin
  };
  return (
    <div className="flex flex-col bg-white w-full min-h-64 items-start justify-between m-5 p-4">
      <div>
        <p className="text-body-bold mb-2">{playersInTitle}</p>
        <div className="flex flex-row items-center mb-1">
          <PlayerInList player={me as Partial<Player>} description={playersInDescription} />
        </div>
        {playersIn.length > 0 &&
          playersIn.map((player, index) => (
            <PlayerInList key={index} player={player} description={playersInDescription} />
          ))}
      </div>
      <div className="mt-4">
        <p className="text-body-bold mb-2">{playersOutTitle}</p>
        {playersOut.length > 0 &&
          playersOut.map((player, index) => (
            <PlayerOutList key={index} player={player} description={playersOutDescription} />
          ))}
      </div>
    </div>
  );
}

export default RoomPlayersManagerContent;
