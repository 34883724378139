import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import BaseLayout from "../../shared/components/BaseLayout";
import GameHeader from "../../shared/components/GameComponents/GameHeader";
import { useGameRoom } from "../../providers/room";
import { usePreGame } from "../../providers/preGame";
import { useGame } from "../../providers/game";
import GenericModal from "../../shared/components/Modals/GenericModal";
import ProfileCard from "./components/ProfileCard";
import { CommonRoutes } from "../../routes/SystemRoutes";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { gameButtonTexts } from "./utils/GameTexts";

const CommonLayout: React.FC = () => {
  const { room, isLoadingRoom } = useGameRoom();
  const { instruction } = usePreGame();
  const { showTimer, openModal, selectedProfile, handleCloseModal } = useGame();
  const navigate = useNavigate();

  useEffect(() => {
    if (!room && !isLoadingRoom) navigate(CommonRoutes.RoomOptions);
  }, [isLoadingRoom, navigate, room]);

  return (
    <>
      <BaseLayout>
        <div className="flex flex-col items-center">
          <GameHeader instruction={instruction} room={room} showTimer={showTimer} />
          <Outlet />
        </div>
      </BaseLayout>
      {openModal && selectedProfile && (
        <GenericModal handleClose={handleCloseModal} open={openModal} noBackground>
          <div className="flex flex-col items-center h-screen justify-center">
            <ProfileCard
              profile={selectedProfile}
              isDrawingProfile={false}
              maxHeight="85vh"
              yMargin="my-0"
            />
            <CustomButton
              size="large"
              className="w-full mt-4"
              onClick={handleCloseModal}
              textColor="white"
            >
              {gameButtonTexts.CLOSE_PROFILE}
            </CustomButton>
          </div>
        </GenericModal>
      )}
    </>
  );
};

export default CommonLayout;
