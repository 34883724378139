import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../../providers/socket";
import { useGameRoom } from "../../../../providers/room";
import { useDeliberation } from "../../Deliberation/hooks/useDeliberation";
import { usePreGame } from "../../../../providers/preGame";
import { CALCULATE_SCORE, MATCH_CHANGES } from "../../../../shared/utils/SocketEvents";
import { useNotification } from "../../../../providers/notify";
import { errorMessages, successMessages } from "../../utils/GameNotifications";
import { useGame } from "../../../../providers/game";
import { gameTexts } from "../../utils/GameTexts";
import { ProfilesVoting } from "../../../../DTOs/Match";
import { GameRoutes } from "../../../../routes/SystemRoutes";

export const useVoting = () => {
  const { socket } = useSocket();
  const { room } = useGameRoom();
  const { matchId, setInstruction, isAdmin } = usePreGame();
  const { notifyError, notifySuccess } = useNotification();
  const { profileSelections, resetSelections } = useDeliberation();
  const navigate = useNavigate();
  const { setNotVotedYet, setAlreadyVoted } = useGame();

  const sendVoting = () => {
    const playerId = room?.player.id;
    const playerGuesses = profileSelections
      ? Object.keys(profileSelections)
          .map((key) => {
            const selection = profileSelections[key];
            return selection
              ? {
                  playerId: selection.playerId,
                  profileId: selection.profileId
                }
              : null;
          })
          .filter((guess) => guess !== null)
      : [];

    const requestBody = {
      matchId,
      playerId,
      playerGuesses
    };
    let responseReceived = false;

    const handleResponse = (response: any) => {
      responseReceived = true;
      socket?.off(MATCH_CHANGES, handleResponse);
      const { error } = response;
      if (!error) {
        if (matchId === response.matchId && response.type === CALCULATE_SCORE) {
          resetSelections();
          notifySuccess(successMessages.VOTING_SENT);
          setNotVotedYet(response.playersNotVoted);
          setAlreadyVoted(
            response.playersVoted.filter((voted: ProfilesVoting) => voted.playerId !== playerId)
          );
          setInstruction(
            isAdmin
              ? gameTexts.WAIT_FOR_ALL_PLAYERS_VOTE_ADMIN
              : gameTexts.WAIT_FOR_ALL_PLAYERS_VOTE
          );
          navigate(GameRoutes.WaitingVotes);
        }
      } else notifyError(errorMessages.FAIL_TO_SEND_VOTING);
    };

    socket?.on(MATCH_CHANGES, handleResponse);

    socket?.emit(CALCULATE_SCORE, requestBody);

    setTimeout(() => {
      if (!responseReceived) {
        socket?.off(MATCH_CHANGES, handleResponse);
        notifyError(errorMessages.FAIL_TO_SEND_VOTING);
      }
    }, 30000);
  };

  return { sendVoting };
};
