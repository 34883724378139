import React, { ReactElement } from "react";
import GenericLoader from "../../../shared/Loaders/GenericLoader";
import { gameTexts } from "../utils/GameTexts";
import { Profile } from "../../../DTOs/Profile";

interface ProfileCardProps {
  profile: Profile | null;
  isDrawingProfile: boolean;
  maxHeight?: string;
  yMargin?: string;
}

function ProfileCard({
  profile,
  isDrawingProfile,
  maxHeight = "100vh",
  yMargin = "my-8"
}: ProfileCardProps): ReactElement {
  return isDrawingProfile ? (
    <div className="bg-blue-500 flex items-center justify-center rounded-3xl max-w-md shadow-lg my-8 min-w-72 min-h-24">
      <GenericLoader screen={false} />
    </div>
  ) : profile ? (
    <div
      className={`flex flex-col items-center rounded-3xl py-6 max-w-md shadow-lg text-center ${yMargin}`}
      style={{ backgroundColor: profile?.primaryColor, maxHeight: maxHeight }}
    >
      <div
        className="flex flex-col px-6 items-center text-center overflow-y-auto overflow-x-hidden"
        style={{ backgroundColor: profile?.primaryColor }}
      >
        <img
          src={`data:image/svg+xml;base64,${profile?.imageProfileNumber}`}
          alt="profile"
          className="w-80 mb-6 ml-6"
        />
        <div
          className="text-body-regular-2 font-medium max-h-56 mb-6"
          style={{ color: profile?.secondaryColor }}
        >
          <div className="mr-2" dangerouslySetInnerHTML={{ __html: profile?.description || "" }} />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="flex flex-col bg-blue-500 items-center rounded-3xl p-6 max-w-md shadow-lg text-center mb-4 mt-8 overflow-auto"
      style={{ maxHeight: "80vh" }}
    >
      <p className="text-body-regular-2 font-medium text-white">{gameTexts.DRAW_PROFILE_FAILED}</p>
    </div>
  );
}

export default ProfileCard;
