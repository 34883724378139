/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import * as themeManager from "../../../../managers/ThemeManager";
import { Theme } from "../../../../DTOs/Theme";
import { useNotification } from "../../../../providers/notify";
import { errorMessages } from "../../utils/GameNotifications";
import { useSocket } from "../../../../providers/socket";
import { MATCH_CHANGES, NEW_MATCH, THEME_RAFFLE } from "../../../../shared/utils/SocketEvents";
import { useLocation, useNavigate } from "react-router-dom";
import { useGameRoom } from "../../../../providers/room";
import { usePreGame } from "../../../../providers/preGame";
import { GameRoutes } from "../../../../routes/SystemRoutes";

export const useDrawTheme = () => {
  const { socket } = useSocket();
  const { setLoadingTheme, setTheme, theme, isAdmin, isDrawingTheme, setIsDrawingTheme } =
    usePreGame();
  const { room } = useGameRoom();
  const { notifyError } = useNotification();
  const [allThemes, setAllThemes] = useState<Theme[]>([]);
  const [unDrawnThemes, setUnDrawnThemes] = useState<Theme[]>([]);
  const unDrawnThemesRef = useRef<Theme[]>(unDrawnThemes);
  const [drawThemes, setDrawThemes] = useState<Theme[]>([]);
  const drawThemesRef = useRef<Theme[]>(drawThemes);
  const themeRef = useRef<Theme | null>(theme);
  const location = useLocation();
  const navigate = useNavigate();
  const shouldDrawTheme = location.pathname === GameRoutes.DrawTheme && isAdmin;

  useEffect(() => {
    if (shouldDrawTheme) {
      unDrawnThemesRef.current = unDrawnThemes;
      if (unDrawnThemesRef.current.length <= 0) {
        setUnDrawnThemes(allThemes);
        setDrawThemes([]);
        unDrawnThemesRef.current = allThemes;
        drawThemesRef.current = [];
      }
    }
  }, [unDrawnThemes]);

  useEffect(() => {
    if (shouldDrawTheme) {
      drawThemesRef.current = drawThemes;
    }
  }, [drawThemes]);

  useEffect(() => {
    if (shouldDrawTheme) {
      themeRef.current = theme;
    }
  }, [theme]);

  const confirmTheme = () => {
    if (isAdmin) {
      socket?.emit(NEW_MATCH, { roomId: room?.id, themeId: theme?.id });
    }
  };

  const getAllThemes = async () => {
    try {
      const themes = await themeManager.getAll();
      setAllThemes(themes);
      setUnDrawnThemes(themes);
    } catch (error) {
      notifyError(errorMessages.GET_ALL_THEMES_FAILED);
    } finally {
      setLoadingTheme(false);
    }
  };

  const drawTheme = useCallback(async () => {
    setIsDrawingTheme(true);

    let newTheme: Theme;
    let attempts = 0;
    const maxAttempts = 50;

    do {
      const randomIndex = Math.floor(Math.random() * unDrawnThemesRef.current.length);
      newTheme = unDrawnThemesRef.current[randomIndex];
      attempts++;
    } while (newTheme?.id === themeRef.current?.id && attempts < maxAttempts);

    if (attempts >= maxAttempts) {
      console.error("Não foi possível encontrar um novo tema diferente do atual.");
      notifyError(errorMessages.GET_ALL_THEMES_FAILED);
      setIsDrawingTheme(false);
      return;
    }

    setTheme(newTheme);
    setDrawThemes((prev) => [...(prev || []), newTheme]);
    setUnDrawnThemes((prev) => prev?.filter((t) => t.id !== newTheme?.id));

    socket?.emit(THEME_RAFFLE, { theme: newTheme, seed: room?.seed });

    setTimeout(() => {
      setIsDrawingTheme(false);
    }, 2000);
  }, [allThemes, socket]);

  useEffect(() => {
    const initializeThemes = async () => {
      if (location.pathname === GameRoutes.DrawTheme && isAdmin) {
        await getAllThemes();
      }
    };

    initializeThemes();
  }, [isAdmin]);

  useEffect(() => {
    if (allThemes.length > 0 && unDrawnThemesRef.current.length > 0 && shouldDrawTheme) {
      drawTheme();
    }
  }, [allThemes]);

  const handleNewGame = useCallback(() => {
    navigate(GameRoutes.DrawProfile);
  }, [navigate]);

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error && room?.seed === data.seed) {
          switch (data.type) {
            case NEW_MATCH:
              handleNewGame();
              break;
          }
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [socket, handleNewGame, room?.seed]);

  return { drawTheme, confirmTheme, isDrawingTheme };
};
