import React from "react";
import AnimatedText from "../../../shared/animations/AnimatedText";
import CustomButton from "../../../shared/components/Buttons/CustomButton";
import { gameButtonTexts, gameTexts, gameTitles } from "../utils/GameTexts";
import { ProfilesVoting } from "../../../DTOs/Match";
import RoomPlayersManagerContent from "../../../shared/RoomPlayersManager";
import Hourglass from "../../../shared/animations/Hourglass";

interface WaitingVotesContentProps {
  notVotedYet: ProfilesVoting[];
  alreadyVoted: ProfilesVoting[];
  isAdmin: boolean;
  shouldEnableEndingVote: boolean;
  stopVoting: () => void;
}

export default function WaitingVotesContent({
  notVotedYet,
  alreadyVoted,
  isAdmin,
  shouldEnableEndingVote,
  stopVoting
}: WaitingVotesContentProps): JSX.Element {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-52">
        <Hourglass />
      </div>
      <div className="text-body-semibold font-semibold text-center mb-5 text-white">
        <AnimatedText text={gameTexts.WHO_IS_VOTING} />
      </div>
      {isAdmin && shouldEnableEndingVote && (
        <CustomButton size="large" className="my-4 w-11/12" onClick={stopVoting}>
          {gameButtonTexts.FINISH_VOTING}
        </CustomButton>
      )}
      <RoomPlayersManagerContent
        playersIn={alreadyVoted}
        playersOut={notVotedYet}
        playersInTitle={gameTitles.ALREADY_VOTED}
        playersOutTitle={gameTitles.NOT_VOTED_YET}
        isAdmin={isAdmin}
      />
    </div>
  );
}
