export const gameButtonTexts = {
  CLEAN_NAMES: "Limpar Nomes",
  CLOSE_PROFILE: "Fechar Perfil",
  CONFIRM_THEME: "Confirmar Tema",
  CONFIRM_VOTES: "Confirmar Votação",
  CONTINUE: "Continuar",
  DRAW_ANOTHER_THEME: "Sortear Outro Tema",
  FINISH_GAME: "Finalizar Jogo",
  FINISH_MATCH: "Terminar Partida",
  FINISH_VOTING: "Finalizar Votação",
  GO_BACK_TO_HOME: "Voltar para a tela inicial",
  NEW_MATCH: "Nova Rodada",
  START_MATCH: "Iniciar Partida"
};

export const gameTexts = {
  CHOOSE_ANOTHER_THEME: "Sorteie outro tema ou confirme-o",
  DISCUSS_THEME_AND_GUESS_PROFILES: "Discuta o tema e adivinhe os perfis",
  DRAW_PROFILE_FAILED: "Ops... Algo deu errado ao sortear o perfil. Tente novamente mais tarde!",
  DRAW_THEMES_FAILED: "Ops... Algo deu errado ao sortear o tema. Tente novamente mais tarde!",
  GAME_RESULTS_FAILED:
    "Ops... Algo deu errado ao buscar os resultados. Tente novamente mais tarde!",
  KNOW_WHO_IS_READING: "Saiba quem ainda está lendo...",
  MODIFY_PROFILE_ZOOM: "Alterar zoom dos perfis:",
  NOT_SELECTED_PROFILE: " não selecionou perfil",
  NOT_VOTED_YET: "Ainda não votaram",
  READ_PROFILE_ADMIN: "Leia com atenção o seu perfil e inicie a partida",
  READ_PROFILE_PLAYER: "Leia com atenção o seu perfil",
  RESULT: "Resultado",
  SELECT_NAME: "Selecione Nome",
  SELECT_PROFILE_OR_CONFIRM: "Selecione os perfis ou simplesmente confirme",
  SELECTED_PROFILE: " selecionou perfil",
  WAIT_FOR_ADMIN_THEME: "O administrador da sala esta escolhendo o tema",
  WAIT_FOR_ALL_PLAYERS_VOTE: "aguarde todos votarem ou o fim da votação....",
  WAIT_FOR_ALL_PLAYERS_VOTE_ADMIN: "aguarde todos votarem ou finalize a votação....",
  WAIT_FOR_GAME_INIT: "Aguarde o jogo logo irá começar. . . ",
  WHO_IS_VOTING: "saiba quem ainda está votando..."
};

export const gameTitles = {
  ALREADY_VOTED: "Quem já votou",
  GAME_RANKING: "Ranking de pontos da partida",
  MATCH_RANKING: "Ranking de pontos da rodada",
  NOT_VOTED_YET: "Quem falta votar",
  READ_PROFILE_ALREADY: "Já leram o próprio perfil selecionado",
  STILL_NOT_READ_PROFILE: "Ainda faltam clicar em continuar"
};
