import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import clsx from "clsx";

interface CustomButtonProps extends ButtonProps {
  backgroundColor?: string;
  textColor?: string;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  customStyle?: SxProps<Theme>;
  iconSrc?: string;
  iconAlt?: string;
  loading?: boolean;
  styleButton?: "primary" | "secondary" | "none";
  disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  backgroundColor,
  textColor,
  size = "medium",
  customStyle,
  className,
  iconSrc,
  iconAlt,
  loading = false,
  styleButton = "primary",
  variant = "contained",
  disabled = false,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      className={clsx(
        "relative",
        disabled
          ? "cursor-not-allowed bg-gray-200 text-gray"
          : styleButton === "primary" && variant === "contained"
          ? "bg-pink-500 hover:bg-pink-600"
          : styleButton === "primary" && variant === "outlined"
          ? "border border-pink-500 text-pink-500 hover:bg-pink-50"
          : styleButton === "primary" && variant === "text"
          ? "text-pink-500 hover:bg-pink-50"
          : styleButton === "secondary" && variant === "contained"
          ? "bg-blue-500 hover:bg-blue-600"
          : styleButton === "secondary" && variant === "outlined"
          ? "border border-blue-500 text-blue-500 hover:bg-blue-50"
          : styleButton === "secondary" && variant === "text"
          ? "text-blue-500 hover:bg-blue-50"
          : "",
        className
      )}
      sx={{
        backgroundColor: disabled
          ? "#E0E0E0"
          : variant === "contained"
          ? backgroundColor
          : undefined,
        color: variant !== "contained" ? textColor : undefined,
        textTransform: "none",
        ...customStyle
      }}
      size={size}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? (
        <>
          <CircularProgress size={24} style={{ color: "white" }} />
          <p className="ml-3 text-white">Carregando...</p>
        </>
      ) : (
        <>
          {iconSrc && (
            <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
              <img src={iconSrc} alt={iconAlt} className="w-5 h-5" />
            </div>
          )}
          {props.children}
        </>
      )}
    </Button>
  );
};

export default CustomButton;
