import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { CreateRoomResponse, Player } from "../DTOs/Player";
import { useNotification } from "./notify";
import {
  ADMIN_DISCONNECTION,
  MATCH_CHANGES,
  NEW_PLAYER,
  PLAYER_DISCONNECTED,
  ROOM_CHANGES,
  SWITCH_ADMIN_PLAYER
} from "../shared/utils/SocketEvents";
import { infoMessages } from "../shared/utils/NotifyMessages";
import { useSocket } from "./socket";

interface GameRoomContextProps {
  room: CreateRoomResponse | null;
  setRoom: React.Dispatch<SetStateAction<CreateRoomResponse | null>>;
  playersIn: Player[];
  playersOut: Player[];
  isLoadingRoom: boolean;
  setPlayersIn: React.Dispatch<SetStateAction<Player[]>>;
  addPlayerIn: (player: Player) => void;
  movePlayerOut: (data: any) => void;
  switchAdminPlayer: (data: any) => void;
  leaveRoom: () => void;
  updatePlayers: (players: Player[], myPlayerId?: string) => void;
}

const GameRoomContext = createContext<GameRoomContextProps | undefined>(undefined);

export const GameRoomProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { socket } = useSocket();
  const [room, setRoom] = useState<CreateRoomResponse | null>(null);
  const [playersIn, setPlayersIn] = useState<Player[]>([]);
  const { notifyError, notifyInfo } = useNotification();
  const [playersOut, setPlayersOut] = useState<Player[]>([]);
  const [isLoadingRoom, setIsLoadingRoom] = useState<boolean>(true);

  useEffect(() => {
    const storedRoom = sessionStorage.getItem("room");

    if (storedRoom) {
      try {
        const parsedRoom = JSON.parse(storedRoom);
        if (parsedRoom) {
          setRoom(parsedRoom);
        } else {
          console.error("Parsed room is null or undefined");
        }
      } catch (error) {
        console.error("Error parsing room from sessionStorage:", error);
      }
    }

    setIsLoadingRoom(false);
  }, []);

  const addPlayerIn = useCallback(
    (player: Player) => {
      if (room && player.id !== room.player.id) {
        setPlayersIn((prevPlayers) => [...prevPlayers, player]);
      }
    },
    [room]
  );

  const updatePlayers = useCallback(
    (players: Player[], myPlayerId?: string) => {
      let updatedPlayers = [];
      if (myPlayerId) updatedPlayers = players.filter((player) => player.id !== myPlayerId);
      else updatedPlayers = players.filter((player) => player.id !== room?.player.id);
      setPlayersIn(updatedPlayers);
    },
    [room]
  );

  const switchAdminPlayer = useCallback(
    async (data: any) => {
      if (data.nextAdminId === room?.player.id) {
        setRoom((prevRoom) => {
          if (prevRoom) {
            return {
              ...prevRoom,
              player: {
                ...prevRoom.player,
                isAdmin: true
              }
            };
          }
          return prevRoom;
        });
        notifyInfo(infoMessages.PLAYER_IS_NOW_ADMIN);
        sessionStorage.setItem("room", JSON.stringify(room));
      }
      updatePlayers(data.roomPlayers);
    },
    [room, notifyInfo, updatePlayers]
  );

  const leaveRoom = useCallback(() => {
    setRoom(null);
    setPlayersIn([]);
    setPlayersOut([]);
    sessionStorage.removeItem("room");
    socket?.off(ROOM_CHANGES);
    socket?.off(MATCH_CHANGES);
  }, [socket]);

  const movePlayerOut = useCallback(
    async (data: any) => {
      const playersToMove = playersIn.filter((player) =>
        data.deletedPlayers.some((deletedPlayer: any) => deletedPlayer === player.id)
      );

      const isCurrentPlayerRemoved = data.deletedPlayers.some(
        (deletedPlayer: any) => deletedPlayer === room?.player.id
      );

      if (isCurrentPlayerRemoved && data.type !== ADMIN_DISCONNECTION) {
        notifyError(infoMessages.PLAYER_REMOVED_BY_ADMIN);
        leaveRoom();
      } else if (playersToMove.length > 0) {
        updatePlayers(data.roomPlayers);
        setPlayersOut((prevPlayers) => [...prevPlayers, ...playersToMove]);
      }
      if (data.nextAdminId) switchAdminPlayer(data);
    },
    [playersIn, switchAdminPlayer, room?.player.id, notifyError, updatePlayers, leaveRoom]
  );

  useEffect(() => {
    if (socket) {
      const handleRoomChanges = (data: any) => {
        const { error } = data;
        if (!error && room?.seed === data.seed) {
          switch (data.type) {
            case NEW_PLAYER:
              addPlayerIn(data.currentPlayer);
              break;

            case PLAYER_DISCONNECTED:
              movePlayerOut(data);
              break;

            case SWITCH_ADMIN_PLAYER:
              switchAdminPlayer(data);
              break;

            case ADMIN_DISCONNECTION:
              movePlayerOut(data);
              break;

            default:
              break;
          }
        }
      };

      socket.on(ROOM_CHANGES, handleRoomChanges);

      return () => {
        socket.off(ROOM_CHANGES, handleRoomChanges);
      };
    }
  }, [addPlayerIn, movePlayerOut, room?.seed, switchAdminPlayer, socket]);

  return (
    <GameRoomContext.Provider
      value={{
        room,
        setRoom,
        playersIn,
        playersOut,
        isLoadingRoom,
        setPlayersIn,
        addPlayerIn,
        movePlayerOut,
        switchAdminPlayer,
        updatePlayers,
        leaveRoom
      }}
    >
      {children}
    </GameRoomContext.Provider>
  );
};

export const useGameRoom = () => {
  const context = useContext(GameRoomContext);
  if (!context) {
    throw new Error("useGameRoom must be used in a GameRoomProvider");
  }
  return context;
};
