import React from "react";
import "./App.css";
import RoutesSystem from "./routes/routes";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";

function App(): JSX.Element {
  return (
    <>
      <ThemeProvider theme={theme}>
        <RoutesSystem />
      </ThemeProvider>
    </>
  );
}

export default App;
