import React from "react";
import { MatchResult } from "../../../DTOs/Result";
import trophyIcon from "../../../assets/icons/trophy.svg";

interface ColumnConfig {
  header: string;
  accessor: keyof MatchResult;
  style?: React.CSSProperties;
}

interface ResultTableProps {
  rows: MatchResult[];
  columns: ColumnConfig[];
  showTrophyIcon?: boolean;
}

const ResultTable: React.FC<ResultTableProps> = ({ rows, columns, showTrophyIcon = false }) => {
  return (
    <div className="flex flex-row w-full min-h-36 items-start">
      {showTrophyIcon && rows.length > 0 && (
        <div className="w-auto ml-[-10px] mr-2 mt-[48px] self-start">
          <img src={trophyIcon} alt="trophy" className="w-6" />
        </div>
      )}
      <div style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index} style={column.style || headerStyle}>
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr style={{ backgroundColor: row.primaryColor }}>
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        textAlign: colIndex === columns.length - 1 ? "right" : "left",
                        padding: "5px 8px",
                        fontWeight: 500,
                        color: row.secondaryColor,
                        ...column.style
                      }}
                    >
                      {row[column.accessor]}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td colSpan={columns.length} style={{ padding: 0 }}>
                    <hr
                      style={{ margin: 0, border: "none", borderBottom: "10px solid transparent" }}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const headerStyle: React.CSSProperties = {
  textAlign: "left",
  fontWeight: 600,
  padding: "10px",
  backgroundColor: "transparent"
};

export default ResultTable;
