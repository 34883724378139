import React, { ReactElement } from "react";
import ThemeCard from "../components/ThemeCard";
import CustomButton from "../../../shared/components/Buttons/CustomButton";
import { gameButtonTexts, gameTexts } from "../utils/GameTexts";
import { CreateRoomResponse } from "../../../DTOs/Room";
import AnimatedText from "../../../shared/animations/AnimatedText";
import { Theme } from "../../../DTOs/Theme";

interface DrawThemeProps {
  room: CreateRoomResponse | null;
  currentTheme: Theme | null;
  drawTheme: () => void;
  confirmTheme: () => void;
  isDrawingTheme: boolean;
  isAdmin: boolean;
}

function DrawThemeContent({
  room,
  currentTheme,
  drawTheme,
  confirmTheme,
  isDrawingTheme,
  isAdmin
}: DrawThemeProps): ReactElement {
  return (
    <>
      <ThemeCard theme={currentTheme} isDrawingTheme={isDrawingTheme} />
      {isAdmin && (
        <div className="flex flex-col w-full items-center px-6">
          <CustomButton
            size="large"
            styleButton="secondary"
            className="my-4 w-11/12"
            onClick={() => drawTheme()}
            loading={isDrawingTheme}
          >
            {gameButtonTexts.DRAW_ANOTHER_THEME}
          </CustomButton>
          <CustomButton
            size="large"
            className="mb-4 w-11/12"
            onClick={() => confirmTheme()}
            disabled={isDrawingTheme}
          >
            {gameButtonTexts.CONFIRM_THEME}
          </CustomButton>
        </div>
      )}
      {!isAdmin && currentTheme && (
        <div className="text-body-semibold mt-4 text-white text-center mb-10">
          <AnimatedText text={gameTexts.WAIT_FOR_GAME_INIT} />
        </div>
      )}
    </>
  );
}

export default DrawThemeContent;
