import React, { ReactElement, useEffect } from "react";
import BaseLayout from "../../shared/components/BaseLayout";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { START_GAME } from "../../shared/utils/ButtonTexts";
import { useNavigate } from "react-router-dom";
import {
  IS_IN_THE_ROOM,
  LEFT_THE_ROOM,
  MEMBERS_OUT,
  ROOM_MEMBERS,
  ROOM_START_INSTRUCTION,
  ROOM_WAIT_INSTRUCTIONS
} from "./utils/LobbyTexts";
import RoomPlayersManager from "../../shared/RoomPlayersManager";
import { CreateRoomResponse, Player } from "../../DTOs/Player";
import GameHeader from "../../shared/components/GameComponents/GameHeader";
import { CommonRoutes } from "../../routes/SystemRoutes";

interface LobbyContentProps {
  room: CreateRoomResponse | null;
  playersIn: Player[];
  playersOut: Player[];
  isLoadingRoom: boolean;
  handleStartGame: () => void;
}

function LobbyContent({
  room,
  isLoadingRoom,
  playersIn,
  playersOut,
  handleStartGame
}: LobbyContentProps): ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    if (!room && !isLoadingRoom) navigate(CommonRoutes.RoomOptions);
  }, [isLoadingRoom, navigate, room]);

  return (
    <BaseLayout>
      <div className="flex flex-col items-center justify-center">
        <GameHeader
          room={room}
          instruction={room?.player?.isAdmin ? ROOM_START_INSTRUCTION : ROOM_WAIT_INSTRUCTIONS}
          animatedInstruction={!room?.player?.isAdmin}
        />
        <RoomPlayersManager
          playersIn={playersIn}
          playersOut={playersOut}
          playersInTitle={ROOM_MEMBERS}
          playersInDescription={IS_IN_THE_ROOM}
          playersOutTitle={MEMBERS_OUT}
          playersOutDescription={LEFT_THE_ROOM}
          isAdmin={room?.player?.isAdmin}
        />
        {room?.player?.isAdmin && (
          <CustomButton
            textColor="white"
            size="large"
            customStyle={{ margin: 2 }}
            className="w-full bg-pink-500 hover:bg-pink-600"
            onClick={() => handleStartGame()}
          >
            {START_GAME}
          </CustomButton>
        )}
      </div>
    </BaseLayout>
  );
}

export default LobbyContent;
