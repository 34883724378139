import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomTooltip from "../Tooltip";
import { CommonRoutes } from "../../../routes/SystemRoutes";

interface GoBackButtonProps {
  color?: string;
}

const GoBackButton: React.FC<GoBackButtonProps> = ({ color = "bg-pink-500" }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (location.pathname === CommonRoutes.RoomOptions) {
      navigate(CommonRoutes.GameHome);
    } else {
      navigate(-1);
    }
  };

  return (
    <CustomTooltip title="Voltar" arrow>
      <button
        onClick={handleGoBack}
        className={`flex items-center justify-center w-9 h-9 rounded-full ${color} text-white border-[3px] border-white shadow-lg`}
        aria-label="go-back"
      >
        <ArrowBackIcon sx={{ fontSize: "15.5px" }} />
      </button>
    </CustomTooltip>
  );
};

export default GoBackButton;
