import React, { useState } from "react";
import CustomTooltip from "../Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CreateRoomResponse } from "../../../DTOs/Player";
import Badge from "../Badge";
import { ROOM_CODE } from "../../../pages/Lobby/utils/LobbyTexts";
import Timer from "../../../pages/Game/components/Timer";
import { useGame } from "../../../providers/game";
import AnimatedText from "../../animations/AnimatedText";

interface GameHeaderProps {
  room: CreateRoomResponse | null;
  instruction: string;
  showTimer?: boolean;
  animatedInstruction?: boolean;
}

const GameHeader: React.FC<GameHeaderProps> = ({
  room,
  instruction,
  showTimer = false,
  animatedInstruction = false
}) => {
  const [tooltipTitle, setTooltipTitle] = useState<string>("Copiar código");
  const { matchInfos } = useGame();
  const [openTooltip, setOpenTooltip] = useState(false);
  const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  function copySeedToClipboard(): void {
    const seedWithoutFirstChar = room?.seed?.substring(1) ?? "";
    navigator.clipboard.writeText(seedWithoutFirstChar).then(() => {
      setTooltipTitle("Código copiado!");
      if (isMobile) {
        setOpenTooltip(true);
        setTimeout(() => {
          setOpenTooltip(false);
        }, 2000);
      }
    });
  }

  function handleMouseLeave(): void {
    setTooltipTitle("Copiar código");
  }

  return (
    <>
      <div className="flex flex-row items-center p-5">
        {showTimer && (
          <div className="mr-6">
            <Timer startTime={matchInfos?.startTime} />
          </div>
        )}
        <h4 className="text-body-bold text-white mr-4">{room?.player?.playerName}</h4>
        {room?.player.isAdmin ? <Badge text="admin da sala" /> : <Badge text="player" />}
      </div>
      <div className="flex flex-row items-center px-5">
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-body-semibold font-medium text-white mr-4">
            {ROOM_CODE}{" "}
            <span className="text-body-bold text-pink-500 ml-1">
              {room?.seed}
              <button onClick={copySeedToClipboard} onMouseLeave={handleMouseLeave}>
                {isMobile ? (
                  <CustomTooltip title={tooltipTitle} open={openTooltip}>
                    <ContentCopyIcon
                      fontSize="small"
                      className="text-white ml-2 cursor-pointer"
                      sx={{ fontSize: "16px" }}
                    />
                  </CustomTooltip>
                ) : (
                  <CustomTooltip title={tooltipTitle}>
                    <ContentCopyIcon
                      fontSize="small"
                      className="text-white ml-2 cursor-pointer"
                      sx={{ fontSize: "16px" }}
                    />
                  </CustomTooltip>
                )}
              </button>
            </span>
          </h4>
          <h5 className="text-body-semibold font-medium mt-4 text-white text-center">
            {animatedInstruction ? <AnimatedText text={instruction} /> : instruction}
          </h5>
        </div>
      </div>
    </>
  );
};

export default GameHeader;
