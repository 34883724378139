import React from "react";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../../providers/auth";
import CustomTooltip from "../Tooltip";
import { CommonRoutes } from "../../../routes/SystemRoutes";

interface LogoutButtonProps {
  color?: string;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ color = "bg-pink-500" }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(CommonRoutes.Home);
  };

  return (
    <CustomTooltip title="Sair" arrow>
      <button
        onClick={handleLogout}
        className={`flex items-center justify-center w-9 h-9 rounded-full ${color} text-white border-[3px] border-white shadow-lg`}
        aria-label="go-back"
      >
        <LogoutIcon sx={{ fontSize: "15.5px", transform: "rotate(180deg)", marginRight: "2px" }} />
      </button>
    </CustomTooltip>
  );
};

export default LogoutButton;
