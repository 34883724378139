import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { Theme } from "../DTOs/Theme";
import TransitionLoader from "../shared/Loaders/TransitionLoader";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import AnimatedText from "../shared/animations/AnimatedText";
import { gameTexts } from "../pages/Game/utils/GameTexts";
import { useGameRoom } from "./room";
import { useSocket } from "./socket";
import {
  MATCH_CHANGES,
  NEW_MATCH,
  PROFILE_RAFFLE,
  PROFILE_RAFFLE_CONTINUE,
  START_MATCH,
  THEME_RAFFLE
} from "../shared/utils/SocketEvents";
import { Profile } from "../DTOs/Profile";
import * as ProfileManager from "../managers/ProfileManager";
import { useNotification } from "./notify";
import { errorMessages } from "../shared/utils/NotifyMessages";
import { Player } from "../DTOs/Player";
import { MatchProfile } from "../DTOs/Match";

interface PreGameContextProps {
  theme: Theme | null;
  setTheme: React.Dispatch<SetStateAction<Theme | null>>;
  loadingTheme: boolean;
  setLoadingTheme: React.Dispatch<SetStateAction<boolean>>;
  instruction: string;
  setInstruction: React.Dispatch<SetStateAction<string>>;
  isAdmin: boolean;
  isDrawingTheme: boolean;
  setIsDrawingTheme: React.Dispatch<SetStateAction<boolean>>;
  profile: Profile | null;
  isDrawingProfile: boolean;
  resetPreGameData: () => void;
  alreadyRead: ProfilesRaffledContinue[];
  notReadYet: Player[];
  matchId: string;
  startMatch: () => void;
  matchProfile: MatchProfile | null;
}

interface ProfilesRaffled {
  profileId: string;
  playerId: string;
}

export interface ProfilesRaffledContinue {
  matchId: string;
  playerName: string;
  playerId: string;
  isAdmin: false;
}

const PreGameContext = createContext<PreGameContextProps | undefined>(undefined);

export const PreGameProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { socket } = useSocket();
  const [theme, setTheme] = useState<Theme | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const { room, playersIn } = useGameRoom();
  const isAdmin = room?.player?.isAdmin || false;
  const [loadingTheme, setLoadingTheme] = useState<boolean>(false);
  const initialInstruction = isAdmin
    ? gameTexts.CHOOSE_ANOTHER_THEME
    : gameTexts.WAIT_FOR_ADMIN_THEME;
  const [instruction, setInstruction] = useState<string>(initialInstruction);
  const [isDrawingTheme, setIsDrawingTheme] = useState(true);
  const [isDrawingProfile, setIsDrawingProfile] = useState(true);
  const [matchId, setMatchId] = useState<string>("");
  const [notReadYet, setNotReadYet] = useState<Player[]>([]);
  const [alreadyRead, setAlreadyRead] = useState<ProfilesRaffledContinue[]>([]);
  const [matchProfile, setMatchProfile] = useState<MatchProfile | null>(null);
  const { notifyError } = useNotification();

  useEffect(() => {
    const instruction = isAdmin ? gameTexts.CHOOSE_ANOTHER_THEME : gameTexts.WAIT_FOR_ADMIN_THEME;
    setInstruction(instruction);
  }, [isAdmin]);

  const updateTheme = useCallback(
    async (theme: Theme) => {
      if (!room?.player.isAdmin) {
        setIsDrawingTheme(true);
        setTheme(theme);
        setTimeout(() => {
          setIsDrawingTheme(false);
        }, 2000);
      }
    },
    [room?.player.isAdmin]
  );

  const handleNewMatch = useCallback(
    (data: any) => {
      const instruction = isAdmin ? gameTexts.READ_PROFILE_ADMIN : gameTexts.READ_PROFILE_PLAYER;
      setInstruction(instruction);
      setMatchId(data.matchId);
      if (isAdmin) {
        socket?.emit(PROFILE_RAFFLE, { matchId: data.matchId });
      }
      setIsDrawingProfile(true);
      setNotReadYet(playersIn);
    },
    [isAdmin, playersIn, socket]
  );

  const updateProfile = useCallback(
    async (profilesRaffled: ProfilesRaffled[]) => {
      const profilesArray = Object.values(profilesRaffled);
      const myProfile = profilesArray.find(
        (profile: ProfilesRaffled) => profile.playerId === room?.player.id
      );
      if (myProfile) {
        try {
          const profile = await ProfileManager.getProfileById(myProfile?.profileId);
          setProfile(profile);
          const matchProfile = {
            profileId: profile.id,
            playerId: room!.player.id,
            matchId: matchId
          };
          sessionStorage.setItem("matchProfile", JSON.stringify(matchProfile));
          setMatchProfile(matchProfile);
        } catch (error) {
          console.error("Error getting profile by id", error);
        } finally {
          setIsDrawingProfile(false);
        }
      }
    },
    [matchId, room]
  );

  const updateWhoIsReading = useCallback(async (player: ProfilesRaffledContinue) => {
    setAlreadyRead((prev) => [...prev, player]);
    setNotReadYet((prev) => prev.filter((p) => p.id !== player.playerId));
  }, []);

  const startMatch = useCallback(() => {
    socket?.emit(START_MATCH, { matchId });
  }, [matchId, socket]);

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error) {
          if (room?.seed === data.seed || matchId === data.matchId) {
            switch (data.type) {
              case THEME_RAFFLE:
                updateTheme(data.theme);
                break;

              case NEW_MATCH:
                handleNewMatch(data);
                break;

              case PROFILE_RAFFLE:
                updateProfile(data.profilesRaffled);
                break;

              case PROFILE_RAFFLE_CONTINUE:
                if (data.playerId !== room?.player.id) updateWhoIsReading(data);
                break;

              default:
                break;
            }
          }
        } else {
          notifyError(errorMessages.GENERIC_ERROR);
          setIsDrawingTheme(false);
          setIsDrawingProfile(false);
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [
    handleNewMatch,
    notifyError,
    socket,
    updateProfile,
    updateTheme,
    matchId,
    room?.seed,
    room?.player.id,
    updateWhoIsReading
  ]);

  const resetPreGameData = useCallback(() => {
    setTheme(null);
    setProfile(null);
    setMatchId("");
    setIsDrawingTheme(true);
    setIsDrawingProfile(true);
    setLoadingTheme(false);
    setInstruction(initialInstruction);
    setNotReadYet([]);
    setAlreadyRead([]);
    setMatchProfile(null);
  }, [initialInstruction]);

  return (
    <PreGameContext.Provider
      value={{
        theme,
        setTheme,
        loadingTheme,
        setLoadingTheme,
        instruction,
        setInstruction,
        isAdmin,
        isDrawingTheme,
        setIsDrawingTheme,
        profile,
        isDrawingProfile,
        resetPreGameData,
        notReadYet,
        alreadyRead,
        matchId,
        startMatch,
        matchProfile
      }}
    >
      {loadingTheme ? (
        <TransitionLoader>
          <div
            className=" flex flex-col items-center text-heading-1 text-white font-semibold"
            style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
          >
            <AnimatedText text="Sorteando Tema..." />
            <div className="animate-spin-slow m-10">
              <CasinoOutlinedIcon
                style={{
                  fontSize: 50,
                  color: "white",
                  filter: "drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.5))"
                }}
              />
            </div>
          </div>
        </TransitionLoader>
      ) : (
        children
      )}
    </PreGameContext.Provider>
  );
};

export const usePreGame = () => {
  const context = useContext(PreGameContext);
  if (!context) {
    throw new Error("usePreGame must be used in a PreGameProvider");
  }
  return context;
};
