import React from "react";
import { useEffect, useState } from "react";
import { useGame } from "../../../providers/game";
import { usePreGame } from "../../../providers/preGame";
import WaitingVotesContent from "./WaitingVotesContent";
import { useWaitingVotes } from "./hooks/useWaitingVotes";

export default function WaitingVotes(): JSX.Element {
  const { notVotedYet, alreadyVoted } = useGame();
  const { isAdmin } = usePreGame();
  const { stopVoting } = useWaitingVotes();
  const [shouldEnableEndingVote, setShouldEnableEndingVote] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldEnableEndingVote(true);
    }, 40000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <WaitingVotesContent
      notVotedYet={notVotedYet}
      alreadyVoted={alreadyVoted}
      isAdmin={isAdmin}
      shouldEnableEndingVote={shouldEnableEndingVote}
      stopVoting={stopVoting}
    />
  );
}
