import React, { useState } from "react";
import { Box, RadioGroup, FormControlLabel, Radio, FormControlLabelProps } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import BlockIcon from "@mui/icons-material/Block";
import CustomButton from "../../../CustomButton";
import { Player } from "../../../../../../DTOs/Player";
import { CANCEL } from "../../../../../utils/ButtonTexts";

interface AdminActionsModalProps {
  handleClose: () => void;
  handleConfirm: (admins: string[]) => void;
  players: Player[];
  description: string;
  buttonLabel: string;
  checkIcons?: boolean;
  allowMultipleSelection?: boolean;
  enableActionButton?: boolean;
}

interface CustomFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
  checkIcons: boolean;
}

const CustomFormControlLabel: React.FC<CustomFormControlLabelProps> = ({
  checked,
  checkIcons,
  ...props
}) => (
  <FormControlLabel
    {...props}
    sx={{
      color: checked ? "#2DC071" : "inherit",
      "& .MuiFormControlLabel-label": {
        color: checked ? (checkIcons ? "#2DC071" : "#E0232E") : "inherit",
        fontFamily: "Dosis, sans-serif"
      }
    }}
  />
);

interface CustomRadioProps {
  checkIcons: boolean;
  checked: boolean;
}

const CustomRadio: React.FC<CustomRadioProps> = ({ checkIcons, checked, ...props }) => {
  return (
    <Radio
      disableRipple
      color="default"
      checked={checked}
      checkedIcon={
        checkIcons ? (
          <TaskAltIcon sx={{ color: "#2DC071" }} />
        ) : (
          <BlockIcon sx={{ color: "#E0232E" }} />
        )
      }
      {...props}
    />
  );
};

const AdminActionsModal: React.FC<AdminActionsModalProps> = ({
  players,
  handleClose,
  handleConfirm,
  description,
  buttonLabel,
  checkIcons = true,
  allowMultipleSelection = false,
  enableActionButton = false
}) => {
  const [selectedAdmins, setSelectedAdmins] = useState<string[]>([]);

  const handleAdminChange = (playerId: string) => {
    if (allowMultipleSelection) {
      setSelectedAdmins((prevSelected) =>
        prevSelected.includes(playerId)
          ? prevSelected.filter((id) => id !== playerId)
          : [...prevSelected, playerId]
      );
    } else {
      setSelectedAdmins([playerId]);
    }
  };
  const shouldEnable = selectedAdmins.length > 0 || enableActionButton;
  const isSelected = (playerId: string) => selectedAdmins.includes(playerId);

  return (
    <>
      <p className="mb-2">{description}</p>

      <RadioGroup value={allowMultipleSelection ? undefined : selectedAdmins[0]}>
        {players.map((player) => {
          const isChecked = isSelected(player.id);
          return (
            <CustomFormControlLabel
              key={player.id}
              value={player.id}
              control={<CustomRadio checked={isChecked} checkIcons={checkIcons} />}
              label={player.playerName}
              checked={isChecked}
              onClick={() => handleAdminChange(player.id)}
              checkIcons={checkIcons}
            />
          );
        })}
      </RadioGroup>

      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <CustomButton
          onClick={handleClose}
          variant="outlined"
          className="text-pink-600 text-button-semibold"
          customStyle={{
            borderColor: "#E73088",
            ":hover": {
              borderColor: "#C9126A",
              backgroundColor: "whitesmoke"
            }
          }}
        >
          {CANCEL}
        </CustomButton>
        <CustomButton
          disabled={!shouldEnable}
          onClick={() => handleConfirm(selectedAdmins)}
        >
          {buttonLabel}
        </CustomButton>
      </Box>
    </>
  );
};

export default AdminActionsModal;
