import React from "react";

interface BadgeProps {
  text: string;
  className?: string;
}

const Badge: React.FC<BadgeProps> = ({ text, className = "" }) => {
  return (
    <div className={`flex items-center rounded-lg ${className}`}>
      <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
      <span className="text-green-500 font-medium">{text}</span>
    </div>
  );
};

export default Badge;
