import React, { useState } from "react";
import logoQuePerfil from "../../assets/brand/logo-que-perfil.svg";
import GoBackButton from "./Buttons/GoBackButton";
import MoreButton from "./Buttons/MoreButton";
import { useGameRoom } from "../../providers/room";
import { useAuth } from "../../providers/auth";
import LogoutButton from "./Buttons/LogoutButton";
import { useNavigate } from "react-router-dom";
import { CommonRoutes } from "../../routes/SystemRoutes";
import GenericModal from "./Modals/GenericModal";
import ConfirmModal from "./Modals/ConfirmModal";
import { CONFIRM_GO_TO_HOME } from "./Buttons/MoreButton/utils/MoreButtonActionTexts";

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { room } = useGameRoom();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full flex justify-between items-center p-4 bg-blue-100 z-50 h-16">
      {!room?.player ? <GoBackButton color="bg-pink-500" /> : <div className="w-9" />}
      <div className="flex-grow flex justify-center">
        <button onClick={() => setOpen(true)}>
          <img src={logoQuePerfil} alt="header-logo" className="w-20 mt-2" />
        </button>
      </div>
      {room?.player && <MoreButton />}
      {!room?.player && isAuthenticated && <LogoutButton color="bg-pink-500" />}
      {!room?.player && !isAuthenticated && <div className="w-9" />}
      <GenericModal handleClose={() => setOpen(false)} open={open}>
        <ConfirmModal
          handleClose={() => setOpen(false)}
          handleConfirm={() => navigate(CommonRoutes.GameHome)}
          description={CONFIRM_GO_TO_HOME}
        />
      </GenericModal>
    </div>
  );
};

export default Header;
