import React, { ReactNode } from "react";

interface TransitionLoaderProps {
  children: ReactNode;
  justifyContent?: string;
}

export default function TransitionLoader({ children, justifyContent }: TransitionLoaderProps): JSX.Element {
  return (
    <main
      className={`${justifyContent ?? "justify-around"} flex flex-col bg-yellow bg-cover bg-center items-center min-h-screen h-auto `}
    >
      <div className="flex flex-col w-80 mdMax:w-11/12">{children}</div>
    </main>
  );
}
