import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/system";
import { Profile } from "../../../DTOs/Profile";
import { GuessOption } from "../../../DTOs/Match";
import { gameTexts } from "../utils/GameTexts";
import CustomTooltip from "../../../shared/components/Tooltip";

const CustomFormControl = styled(FormControl)({
  width: "100%",
  marginTop: "7px",
  ".MuiInputLabel-root": {
    color: "#296AA9"
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: "#FFFFFF",
    fontFamily: "Dosis, sans-serif",
    fontSize: "15px",
    borderRadius: "8px",
    height: "32px",
    textAlign: "center",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E6E6E6"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#296AA9"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#296AA9"
    }
  },
  ".MuiSelect-icon": {
    color: "#252B42"
  }
});

interface ProfileSelectProps {
  itIsMe?: boolean;
  profile: Profile | undefined;
  options: GuessOption[];
  handlePlayerSelect: (
    profileId: number,
    oldValue: string,
    selectedPlayerId: string,
    selectedPlayerName: string,
    key: string
  ) => void;
  onClick?: () => void;
  resetFields: boolean;
  refKey: string;
  selectValue?: string;
  cols?: number;
}

const ProfileSelect: React.FC<ProfileSelectProps> = ({
  itIsMe = false,
  profile,
  options,
  handlePlayerSelect,
  onClick,
  resetFields,
  refKey,
  selectValue,
  cols
}) => {
  const [actualValue, setActualValue] = useState("");
  const [cardSize, setCardSize] = useState("w-[6.5rem] h-[6.5rem]");
  const [imagePadding, setImagePadding] = useState("p-[0.65rem]");

  useEffect(() => {
    setActualValue("");
  }, [resetFields]);

  useEffect(() => {
    if (selectValue) {
      setActualValue(selectValue);
    }
  }, [selectValue]);

  useEffect(() => {
    if (cols) {
      if (cols === 1) {
        setCardSize("w-[15rem] h-[15rem]");
        setImagePadding("p-[1.6rem]");
      } else if (cols === 2) {
        setCardSize("w-[9rem] h-[9rem]");
        setImagePadding("p-[0.9rem]");
      } else if (cols === 3) {
        setCardSize("w-[6.5rem] h-[6.5rem]");
        setImagePadding("p-[0.65rem]");
      }
    }
  }, [cols]);

  return (
    <div
      className={`flex flex-col items-center p-2 m-2 ${
        itIsMe ? "bg-pink-500" : ""
      } rounded-lg w-auto h-auto`}
    >
      <CustomTooltip title={profile?.name ?? ""} arrow placement="top">
        <button
          className={`flex flex-col cursor-pointer items-center justify-center ${cardSize} bg-white rounded-full border border-gray-150`}
          onClick={onClick}
        >
          <img
            src={`data:image/svg+xml;base64,${profile?.imageProfileIcon}`}
            alt="profile-select"
            className={`w-full ${imagePadding} mb-2`}
          />
        </button>
      </CustomTooltip>

      <CustomFormControl>
        {itIsMe ? (
          <TextField
            defaultValue="Eu"
            InputProps={{
              readOnly: true
            }}
            sx={{
              fontFamily: "Dosis, sans-serif",
              fontSize: "15px",
              textAlign: "center",
              "& .MuiInputBase-input": {
                textAlign: "center"
              }
            }}
          />
        ) : (
          <Select
            value={actualValue}
            IconComponent={KeyboardArrowDownIcon}
            sx={{
              "& .MuiSelect-select": {
                textAlign: "center"
              }
            }}
            displayEmpty
            onChange={(e) => {
              const selectedOption = options.find((option) => option.playerId === e.target.value);
              handlePlayerSelect(
                profile!.id,
                actualValue,
                e.target.value as string,
                selectedOption?.playerName || "",
                refKey
              );
              setActualValue(e.target.value as string);
            }}
          >
            <MenuItem value="" sx={{ fontFamily: "Dosis, sans-serif" }}>
              {gameTexts.SELECT_NAME}
            </MenuItem>
            {options?.map((option, index) => (
              <MenuItem
                key={index}
                value={option.playerId}
                sx={{ fontFamily: "Dosis, sans-serif" }}
                disabled={option.disabled}
              >
                {option.playerName}
              </MenuItem>
            ))}
          </Select>
        )}
      </CustomFormControl>
    </div>
  );
};

export default ProfileSelect;
