import React from "react";
import PreGameContent from "./PreGameContent";
import { usePreGame } from "../../../providers/preGame";
import { usePreGameHook } from "./hooks/usePreGameHook";

export default function PreGame(): JSX.Element {
  const { isDrawingProfile, notReadYet, alreadyRead, isAdmin, startMatch } = usePreGame();
  // eslint-disable-next-line no-empty-pattern
  const {} = usePreGameHook();

  return (
    <PreGameContent
      isDrawingProfile={isDrawingProfile}
      notReadYet={notReadYet}
      alreadyRead={alreadyRead}
      isAdmin={isAdmin}
      startMatch={startMatch}
    />
  );
}
