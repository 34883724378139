import { useNavigate } from "react-router-dom";
import { usePreGame } from "../../../providers/preGame";
import { useSocket } from "../../../providers/socket";
import { useCallback, useEffect } from "react";
import {
  FINISH_GAME,
  MATCH_CHANGES,
  RETURN_TO_LOBBY,
  ROOM_CHANGES
} from "../../../shared/utils/SocketEvents";
import { useGameRoom } from "../../../providers/room";
import { GameRoutes } from "../../../routes/SystemRoutes";

export const useMatchResult = () => {
  const { socket } = useSocket();
  const { matchId } = usePreGame();
  const { room } = useGameRoom();
  const navigate = useNavigate();

  const handleReturnToLobby = useCallback(() => {
    navigate(GameRoutes.Lobby);
  }, [navigate]);

  const handleClickReturnToLobby = useCallback(() => {
    socket?.emit(RETURN_TO_LOBBY, { seed: room?.seed });
  }, [socket, room?.seed]);

  const handleFinishGame = useCallback(() => {
    navigate(GameRoutes.GameResult);
  }, [navigate]);

  const handleClickFinishGame = useCallback(() => {
    socket?.emit(FINISH_GAME, { matchId });
  }, [socket, matchId]);

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error && matchId === data.matchId) {
          switch (data.type) {
            case FINISH_GAME:
              handleFinishGame();
              break;
            default:
              break;
          }
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [socket, handleFinishGame, matchId]);

  useEffect(() => {
    if (socket) {
      const handleRoomChanges = (data: any) => {
        const { error } = data;
        if (!error && room?.seed === data.seed) {
          switch (data.type) {
            case RETURN_TO_LOBBY:
              handleReturnToLobby();
              break;
            default:
              break;
          }
        }
      };

      socket.on(ROOM_CHANGES, handleRoomChanges);

      return () => {
        socket.off(ROOM_CHANGES, handleRoomChanges);
      };
    }
  }, [socket, handleReturnToLobby, room?.seed]);

  return { handleClickFinishGame, handleClickReturnToLobby };
};
