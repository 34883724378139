import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../../providers/socket";
import { MATCH_CHANGES, START_MATCH } from "../../../../shared/utils/SocketEvents";
import { useGameRoom } from "../../../../providers/room";
import { usePreGame } from "../../../../providers/preGame";
import { gameTexts } from "../../utils/GameTexts";
import { useCallback, useEffect } from "react";
import { GameRoutes } from "../../../../routes/SystemRoutes";
import { useGame } from "../../../../providers/game";

export const usePreGameHook = () => {
  const { socket } = useSocket();
  const { room } = useGameRoom();
  const { setInstruction } = usePreGame();
  const { handleSetMatchInfos } = useGame();
  const navigate = useNavigate();

  const handleStartMatch = useCallback(
    (data: any) => {
      handleSetMatchInfos({ seed: data.seed, startTime: data.startTime });
      navigate(GameRoutes.Deliberation);
      setInstruction(gameTexts.DISCUSS_THEME_AND_GUESS_PROFILES);
    },
    [navigate, setInstruction, handleSetMatchInfos]
  );

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error && room?.seed === data.seed) {
          switch (data.type) {
            case START_MATCH:
              handleStartMatch(data);
              break;
          }
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [socket, room?.seed, handleStartMatch]);

  return {};
};
