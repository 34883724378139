import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import GenericLoader from "../shared/Loaders/GenericLoader";

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  tempUser: TempUser | null;
  setSession: (userData: User) => void;
  setTempSession: (userData: TempUser) => void;
  logout: () => void;
  loading: boolean;
  isUserAuthenticated: () => boolean;
  isTempUserAuthenticated: () => boolean;
  setLoading: (loading: boolean) => void;
}

export interface User {
  accessToken: string;
  role: string;
  id: string;
}

interface TempUser {
  accessToken: string;
  role: string;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [tempUser, setTempUser] = useState<TempUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setIsAuthenticated(true);
    }

    const storedTempUser = sessionStorage.getItem("tempUser");
    if (storedTempUser) {
      setTempUser(JSON.parse(storedTempUser));
    }

    setLoading(false);
  }, []);

  const setSession = (userData: User) => {
    setUser(userData);
    setIsAuthenticated(true);
    sessionStorage.setItem("user", JSON.stringify(userData));
  };

  const setTempSession = (userData: TempUser) => {
    setTempUser(userData);
    sessionStorage.setItem("tempUser", JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    sessionStorage.removeItem("user");
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  const isUserAuthenticated = (): boolean => {
    const user = sessionStorage.getItem("user");
    const parsedUser: User | null = user ? JSON.parse(user) : null;
    return parsedUser?.accessToken ? true : false;
  };

  const isTempUserAuthenticated = (): boolean => {
    const tempUser = sessionStorage.getItem("tempUser");
    const parsedUser: TempUser | null = tempUser ? JSON.parse(tempUser) : null;
    return parsedUser?.accessToken ? true : false;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        tempUser,
        setSession,
        setTempSession,
        logout,
        loading,
        isUserAuthenticated,
        isTempUserAuthenticated,
        setLoading
      }}
    >
      {loading ? <GenericLoader screen /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthState => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
};
