import React, { ReactElement, useState } from "react";
import ThemeCard from "../components/ThemeCard";
import CustomButton from "../../../shared/components/Buttons/CustomButton";
import { gameButtonTexts, gameTexts } from "../utils/GameTexts";
import { Theme } from "../../../DTOs/Theme";
import { GameProfile, Profile } from "../../../DTOs/Profile";
import { GuessOption, MatchProfile } from "../../../DTOs/Match";
import ProfileSelect from "../components/ProfileSelect";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import GenericLoader from "../../../shared/Loaders/GenericLoader";

interface DeliberationProps {
  theme: Theme | null;
  isAdmin: boolean;
  profilePlayers: GameProfile[] | null;
  options: GuessOption[];
  matchProfile: MatchProfile | null;
  handlePlayerSelect: (
    profileId: number,
    oldValue: string,
    selectedPlayerId: string,
    selectedPlayerName: string,
    key: string
  ) => void;
  renderProfileModal: (profile: Profile | undefined) => void;
  handleResetSelections: () => void;
  resetFields: boolean;
  seconds: number;
  handleFinishMatch: () => void;
  loadingProfiles: boolean;
}

function Deliberation({
  theme,
  isAdmin,
  profilePlayers,
  options,
  matchProfile,
  handlePlayerSelect,
  renderProfileModal,
  handleResetSelections,
  resetFields,
  seconds,
  handleFinishMatch,
  loadingProfiles
}: DeliberationProps): ReactElement {
  const [cols, setCols] = useState(3);

  const increaseCols = () => {
    setCols((prev) => Math.min(prev + 1, 3));
  };

  const decreaseCols = () => {
    setCols((prev) => Math.max(prev - 1, 1));
  };

  return (
    <>
      <ThemeCard theme={theme} />
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-row justify-end items-center mb-4">
          <p className="text-white font-semibold mr-4">{gameTexts.MODIFY_PROFILE_ZOOM}</p>
          <button
            className={`flex items-center mr-1 justify-center w-5 h-5 ${
              cols === 3 ? "bg-gray-600 pointer-events-none" : "bg-pink-500"
            } rounded-full text-white shadow-lg`}
            onClick={increaseCols}
          >
            <RemoveIcon sx={{ fontSize: "15px", marginLeft: "1px" }} />
          </button>
          <button
            className={`flex items-center justify-center w-5 h-5 ${
              cols === 1 ? "bg-gray-600 pointer-events-none" : "bg-pink-500"
            } rounded-full text-white shadow-lg`}
            onClick={decreaseCols}
          >
            <AddIcon sx={{ fontSize: "15px", marginLeft: "1px" }} />
          </button>
        </div>
      </div>
      {loadingProfiles ? (
        <GenericLoader screen={false} />
      ) : (
        <div
          className={`grid ${
            cols === 1 ? "grid-cols-1" : cols === 2 ? "grid-cols-2" : "grid-cols-3"
          }`}
        >
          {profilePlayers?.map((e) => (
            <ProfileSelect
              key={e?.playerId + e.profile?.id}
              profile={e?.profile}
              options={options}
              itIsMe={
                matchProfile?.profileId === e?.profile?.id && matchProfile?.playerId === e?.playerId
              }
              handlePlayerSelect={handlePlayerSelect}
              onClick={() => renderProfileModal(e?.profile)}
              resetFields={resetFields}
              refKey={e?.playerId + e.profile?.id}
              cols={cols}
            />
          ))}
        </div>
      )}
      <div className="flex flex-col w-full items-center px-6">
        <CustomButton
          size="large"
          styleButton="secondary"
          className="my-4 w-11/12"
          onClick={handleResetSelections}
        >
          {gameButtonTexts.CLEAN_NAMES}
        </CustomButton>
        {isAdmin && (
          <CustomButton
            size="large"
            className="mb-4 w-11/12"
            disabled={seconds < 180}
            onClick={handleFinishMatch}
          >
            {gameButtonTexts.FINISH_MATCH}
          </CustomButton>
        )}
      </div>
    </>
  );
}

export default Deliberation;
