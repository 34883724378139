import React, { useEffect, useState } from "react";
import { usePreGame } from "../../providers/preGame";
import { useGame } from "../../providers/game";
import MatchResultContent from "./MatchResultContent";
import { useGameRoom } from "../../providers/room";
import BaseLayout from "../../shared/components/BaseLayout";
import { useMatchResult } from "./hooks/useMatchResultHook";
import * as RoomManager from "../../managers/RoomManager";
import { useNotification } from "../../providers/notify";

export default function MatchResult(): JSX.Element {
  const { matchResult } = useGame();
  const { isAdmin } = usePreGame();
  const { room } = useGameRoom();
  const { handleClickFinishGame, handleClickReturnToLobby } = useMatchResult();
  const { closeNotification } = useNotification();
  const [resultNumber, setResultNumber] = useState<number>(0);

  useEffect(() => {
    closeNotification();
    const fetchResults = async () => {
      if (room?.id === undefined) return;
      try {
        const result = await RoomManager.getResultsNumber(room.id);
        setResultNumber(result.numberOfMatches);
      } catch (error) {}
    };
    fetchResults();
  }, [room?.id, closeNotification]);

  return (
    <BaseLayout>
      <MatchResultContent
        matchResult={matchResult}
        isAdmin={isAdmin}
        room={room}
        resultNumber={resultNumber}
        handleNewMatch={handleClickReturnToLobby}
        handleFinishGame={handleClickFinishGame}
      />
    </BaseLayout>
  );
}
