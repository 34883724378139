export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const handleKeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>, 
  handleSubmit: () => void
) => {
  if (e.key === "Enter") {
    e.preventDefault();
    handleSubmit();
  }
};
