export const ADMIN_DISCONNECTION = "admin-disconnection";
export const CALCULATE_SCORE = "calculate-score";
export const FINISH_GAME = "finish-game";
export const FINISH_MATCH = "finish-match";
export const MATCH_CHANGES = "match-changes";
export const NEW_MATCH = "new-match";
export const NEW_PLAYER = "new-player";
export const PLAYER_DISCONNECTED = "player-disconnection";
export const PROFILE_RAFFLE = "profile-raffle";
export const PROFILE_RAFFLE_CONTINUE = "profile-raffle-continue";
export const RETURN_TO_LOBBY = "return-to-lobby";
export const ROOM_ADMIN = "room-admin";
export const ROOM_CHANGES = "room-changes";
export const START_MATCH = "start-match";
export const STOP_VOTING = "stop-voting";
export const SWITCH_ADMIN_PLAYER = "switch-admin-player";
export const THEME_RAFFLE = "theme-raffle";
