import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/auth";
import { CommonRoutes } from "./SystemRoutes";

interface PrivateRouteProps {
  allowedRole?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ allowedRole }) => {
  const { user, isAuthenticated, tempUser } = useAuth();

  if (!isAuthenticated && !tempUser) {
    return <Navigate to={CommonRoutes.Login} />;
  } else if (allowedRole === "both") {
    return <Outlet />;
  } else if (user?.role === allowedRole) {
    return <Outlet />;
  }

  return <Navigate to={CommonRoutes.Login} />;
};

export default PrivateRoute;
