import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useGameRoom } from "./room";
import { useSocket } from "./socket";
import {
  CALCULATE_SCORE,
  FINISH_MATCH,
  MATCH_CHANGES,
  START_MATCH,
  STOP_VOTING
} from "../shared/utils/SocketEvents";
import { useNotification } from "./notify";
import { usePreGame } from "./preGame";
import { GuessOption, MatchInfos, MatchPlayer, ProfilesVoting } from "../DTOs/Match";
import { GameProfile, Profile } from "../DTOs/Profile";
import { MatchResult } from "../DTOs/Result";

interface GameContextProps {
  showTimer: boolean;
  matchPlayers: MatchPlayer[];
  selectedProfile: Profile | undefined;
  openModal: boolean;
  renderProfileModal: (profile: Profile | undefined) => void;
  handleCloseModal: () => void;
  seconds: number;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
  resetGameData: () => void;
  handleFinishMatch: () => void;
  profilePlayers: GameProfile[];
  setProfilePlayers: React.Dispatch<React.SetStateAction<GameProfile[]>>;
  profileSelections: { [key: string]: ProfilesVoting | null };
  setProfileSelections: React.Dispatch<
    React.SetStateAction<{ [key: string]: ProfilesVoting | null }>
  >;
  guessOptions: GuessOption[];
  setGuessOptions: React.Dispatch<React.SetStateAction<GuessOption[]>>;
  setShowTimer: React.Dispatch<React.SetStateAction<boolean>>;
  notVotedYet: ProfilesVoting[];
  setNotVotedYet: React.Dispatch<React.SetStateAction<ProfilesVoting[]>>;
  alreadyVoted: ProfilesVoting[];
  setAlreadyVoted: React.Dispatch<React.SetStateAction<ProfilesVoting[]>>;
  matchResult: MatchResult[];
  setMatchResult: React.Dispatch<React.SetStateAction<MatchResult[]>>;
  stopVoting: () => void;
  matchInfos: MatchInfos | undefined;
  handleSetMatchInfos: (data: MatchInfos) => void;
}

const GameContext = createContext<GameContextProps | undefined>(undefined);

export const GameProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { socket } = useSocket();
  const { matchId } = usePreGame();
  const [showTimer, setShowTimer] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const { notifyError } = useNotification();
  const { room } = useGameRoom();
  const [matchPlayers, setMatchPlayers] = useState<MatchPlayer[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<Profile | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [profilePlayers, setProfilePlayers] = useState<GameProfile[]>([]);
  const [profileSelections, setProfileSelections] = useState<{
    [key: string]: ProfilesVoting | null;
  }>({});
  const [guessOptions, setGuessOptions] = useState<GuessOption[]>([]);
  const [notVotedYet, setNotVotedYet] = useState<ProfilesVoting[]>([]);
  const [alreadyVoted, setAlreadyVoted] = useState<ProfilesVoting[]>([]);
  const [matchResult, setMatchResult] = useState<MatchResult[]>([]);
  const [matchInfos, setMatchInfos] = useState<MatchInfos>();

  const handleSetMatchInfos = (data: MatchInfos) => {
    setMatchInfos({ seed: data.seed, startTime: data.startTime });
    sessionStorage.setItem("match", JSON.stringify({ seed: data.seed, startTime: data.startTime }));
  };

  const renderProfileModal = (profile: Profile | undefined) => {
    setSelectedProfile(profile);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMatchPlayers = (players: MatchPlayer[]) => {
    setShowTimer(true);
    setMatchPlayers(players);
  };

  const stopVoting = useCallback(() => {
    socket?.emit(STOP_VOTING, { matchId });
  }, [matchId, socket]);

  const handleNewScore = useCallback(
    (playersNorVoted: MatchPlayer[], playersAlreadyVoted: MatchPlayer[]) => {
      setNotVotedYet(playersNorVoted);
      setAlreadyVoted(
        playersAlreadyVoted.filter((voted: ProfilesVoting) => voted.playerId !== room?.player.id)
      );
      if (playersNorVoted.length === 0) stopVoting();
    },
    [stopVoting, room?.player.id]
  );

  const handleFinishMatch = () => {
    socket?.emit(FINISH_MATCH, { matchId, timer: seconds });
  };

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error) {
          if (room?.seed === data.seed || matchId === data.matchId) {
            switch (data.type) {
              case START_MATCH:
                handleMatchPlayers(data.players);
                break;

              case CALCULATE_SCORE:
                handleNewScore(data.playersNotVoted, data.playersVoted);
                break;

              default:
                break;
            }
          }
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [matchId, notifyError, room?.seed, socket, handleNewScore]);

  const resetGameData = useCallback(() => {
    setShowTimer(false);
    setSeconds(0);
    setSelectedProfile(undefined);
    setOpenModal(false);
    setMatchPlayers([]);
    setProfilePlayers([]);
    setProfileSelections({});
    setGuessOptions([]);
    setNotVotedYet([]);
    setMatchResult([]);
    sessionStorage.removeItem("match");
    sessionStorage.removeItem("matchProfile");
  }, []);

  return (
    <GameContext.Provider
      value={{
        showTimer,
        matchPlayers,
        openModal,
        renderProfileModal,
        selectedProfile,
        handleCloseModal,
        seconds,
        setSeconds,
        resetGameData,
        handleFinishMatch,
        profilePlayers,
        setProfilePlayers,
        profileSelections,
        setProfileSelections,
        guessOptions,
        setGuessOptions,
        setShowTimer,
        notVotedYet,
        setNotVotedYet,
        alreadyVoted,
        setAlreadyVoted,
        matchResult,
        setMatchResult,
        stopVoting,
        matchInfos,
        handleSetMatchInfos
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error("useGame must be used in a GameProvider");
  }
  return context;
};
