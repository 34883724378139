import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../../providers/socket";
import { PROFILE_RAFFLE_CONTINUE } from "../../../../shared/utils/SocketEvents";
import { useGameRoom } from "../../../../providers/room";
import { usePreGame } from "../../../../providers/preGame";
import { gameTexts } from "../../utils/GameTexts";
import { GameRoutes } from "../../../../routes/SystemRoutes";

export const useDrawProfile = () => {
  const { socket } = useSocket();
  const { room } = useGameRoom();
  const { matchId, profile, setInstruction } = usePreGame();
  const navigate = useNavigate();

  const confirmProfile = () => {
    socket?.emit(PROFILE_RAFFLE_CONTINUE, {
      playerId: room?.player.id,
      profileId: profile?.id,
      matchId: matchId
    });
    setInstruction(gameTexts.KNOW_WHO_IS_READING);
    navigate(GameRoutes.PreGame);
  };

  return { confirmProfile };
};
