import { useCallback, useEffect, useState } from "react";
import { GameProfile } from "../../../../DTOs/Profile";
import { useGame } from "../../../../providers/game";
import * as profileManager from "../../../../managers/ProfileManager";
import { usePreGame } from "../../../../providers/preGame";
import { useGameRoom } from "../../../../providers/room";
import { gameTexts } from "../../utils/GameTexts";
import { useSocket } from "../../../../providers/socket";
import { useNavigate } from "react-router-dom";
import { FINISH_MATCH, MATCH_CHANGES } from "../../../../shared/utils/SocketEvents";
import { GameRoutes } from "../../../../routes/SystemRoutes";

export const useDeliberation = () => {
  const {
    matchPlayers,
    profilePlayers,
    setProfilePlayers,
    profileSelections,
    setProfileSelections,
    guessOptions,
    setGuessOptions,
    setShowTimer
  } = useGame();

  const { room } = useGameRoom();
  const { socket } = useSocket();
  const { setInstruction, matchId, matchProfile } = usePreGame();
  const navigate = useNavigate();
  const [loadingProfiles, setLoadingProfiles] = useState(false);

  const handlePlayerSelect = (
    profileId: number,
    oldValue: string,
    selectedPlayerId: string,
    selectedPlayerName: string,
    key: string
  ) => {
    setProfileSelections((prevSelections) => {
      const newSelections = { ...prevSelections };
      if (selectedPlayerId) {
        newSelections[key] = {
          playerName: selectedPlayerName,
          playerId: selectedPlayerId,
          profileId: profileId,
          isAdmin: false
        };
      } else {
        delete newSelections[key];
      }

      setGuessOptions((prevArray) =>
        prevArray.map((player) => ({
          ...player,
          disabled:
            Object.values(newSelections).some(
              (selection) => selection?.playerId === player.playerId
            ) && player.playerId !== oldValue
        }))
      );

      return newSelections;
    });
  };

  const resetSelections = () => {
    setProfileSelections({});
    setGuessOptions((prevArray) =>
      prevArray.map((player) => ({
        ...player,
        disabled: false
      }))
    );
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoadingProfiles(true);
      if (matchPlayers && !(guessOptions.length > 0)) {
        const ids = matchPlayers.map((player) => player.profileId);
        const profiles = await profileManager.getProfilesById(ids);
        const allProfiles: GameProfile[] = matchPlayers.map((p) => {
          const profile = profiles.find((profile) => profile.id === p.profileId);
          return {
            profile: profile,
            playerId: profile ? p.playerId : ""
          };
        });
        const shuffledProfiles = allProfiles
          .filter((p) => p.playerId !== room?.player.id)
          .sort(() => Math.random() - 0.5);
        const myProfile = allProfiles.find((p) => p.playerId === room?.player.id);
        if (myProfile) {
          shuffledProfiles.unshift(myProfile);
        }
        setProfilePlayers(shuffledProfiles);
        const profileArray = matchPlayers
          .filter((player) => player.playerId !== matchProfile?.playerId)
          .map((player) => ({
            playerName: player.playerName,
            playerId: player.playerId,
            disabled: false
          }))
          .sort(() => Math.random() - 0.5);
        setGuessOptions(profileArray);
      }
      setLoadingProfiles(false);
    };
    fetchProfiles();
  }, [
    guessOptions.length,
    matchPlayers,
    matchProfile?.playerId,
    room?.player.id,
    setGuessOptions,
    setProfilePlayers
  ]);

  const handleFinishMatch = useCallback(() => {
    setShowTimer(false);
    navigate(GameRoutes.Voting);
    setInstruction(gameTexts.SELECT_PROFILE_OR_CONFIRM);
  }, [navigate, setInstruction, setShowTimer]);

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error && (room?.seed === data.seed || matchId === data.matchId)) {
          switch (data.type) {
            case FINISH_MATCH:
              handleFinishMatch();
              break;
          }
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [socket, room?.seed, handleFinishMatch, matchId]);

  return { profilePlayers, guessOptions, handlePlayerSelect, resetSelections, profileSelections , loadingProfiles};
};
