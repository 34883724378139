import React, { useState } from "react";
import VotingContent from "./VotingContent";
import { useDeliberation } from "../Deliberation/hooks/useDeliberation";
import { usePreGame } from "../../../providers/preGame";
import { useGame } from "../../../providers/game";
import { useVoting } from "./hooks/useVoting";
import { useWaitingVotes } from "../WaitingVotes/hooks/useWaitingVotes";

export default function Voting(): JSX.Element {
  const { profilePlayers, guessOptions, handlePlayerSelect, resetSelections, profileSelections } =
    useDeliberation();
  const { matchProfile } = usePreGame();
  const { renderProfileModal } = useGame();
  const [resetFields, setResetFields] = useState(false);
  const { sendVoting } = useVoting();
  // eslint-disable-next-line no-empty-pattern
  const {} = useWaitingVotes();

  const handleResetSelections = () => {
    setResetFields(!resetFields);
    resetSelections();
  };

  return (
    <VotingContent
      profilePlayers={profilePlayers}
      options={guessOptions}
      handlePlayerSelect={handlePlayerSelect}
      resetFields={resetFields}
      handleResetSelections={handleResetSelections}
      matchProfile={matchProfile}
      renderProfileModal={renderProfileModal}
      sendVoting={sendVoting}
      profileSelections={profileSelections}
    />
  );
}
