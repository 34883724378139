import React, { ReactElement } from "react";
import CustomInput from "../../shared/components/Inputs/CustomFormInput";
import { FormControl, SelectChangeEvent } from "@mui/material";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { CREATE_REGISTRATION_AND_PLAY } from "../../shared/utils/ButtonTexts";
import CustomSelect from "../../shared/components/Inputs/CustomSelect";
import { RegisterInput } from "./utils/RegisterInputs";
import { COMPLETE_REGISTER, REGISTER_TITLE } from "../../shared/utils/PagesTitles";
import BaseLayout from "../../shared/components/BaseLayout";

interface RegisterContentProps {
  formValues: Record<string, string>;
  registerInputs: RegisterInput[];
  completeRegisterInputs: RegisterInput[];
  errors: Record<string, string>;
  handleSubmit: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => void;
  loading: boolean;
  isCompleteRegister: boolean;
}

function RegisterContent({
  formValues,
  registerInputs,
  completeRegisterInputs,
  errors,
  handleSubmit,
  handleInputChange,
  loading,
  isCompleteRegister
}: RegisterContentProps): ReactElement {
  const inputsToRender = isCompleteRegister ? completeRegisterInputs : registerInputs;

  return (
    <BaseLayout>
      <div className="flex flex-col items-center mt-5">
        <h1 className="bold font-semibold mb-5">
          {isCompleteRegister ? COMPLETE_REGISTER : REGISTER_TITLE}
        </h1>
        {inputsToRender.map((input, index) => (
          <div key={index} className="mb-4 w-full">
            <FormControl variant="standard" className="w-full">
              {input.type !== "select" && !input.options ? (
                <CustomInput
                  label={input.label}
                  placeholder={input.placeholder}
                  name={input.name}
                  value={formValues[input.name] || ""}
                  type={input.type}
                  onChange={
                    handleInputChange as (event: React.ChangeEvent<HTMLInputElement>) => void
                  }
                  error={!!errors[input.name]}
                  helperText={errors[input.name] || input.helperText}
                  autoComplete={input.autoComplete}
                />
              ) : (
                <CustomSelect
                  label={input.label}
                  name={input.name}
                  value={formValues[input.name] || ""}
                  onChange={handleInputChange as (event: SelectChangeEvent<unknown>) => void}
                  options={input.options}
                  error={!!errors[input.name]}
                  helperText={errors[input.name] || ""}
                />
              )}
            </FormControl>
          </div>
        ))}
        <CustomButton
          textColor="white"
          size="large"
          customStyle={{ margin: 2 }}
          className="bg-pink-500 hover:bg-pink-600"
          onClick={handleSubmit}
          loading={loading}
        >
          {isCompleteRegister ? COMPLETE_REGISTER : CREATE_REGISTRATION_AND_PLAY}
        </CustomButton>
      </div>
    </BaseLayout>
  );
}

export default RegisterContent;
