import React from "react";
import CustomTooltip from "../Tooltip";

interface IconButtonProps {
  color?: string;
  icon: React.ReactNode;
  handleClick: () => void;
  tooltipText: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  color = "bg-pink-500",
  icon,
  handleClick,
  tooltipText
}) => {
  return (
    <CustomTooltip title={tooltipText} arrow>
      <button
        onClick={handleClick}
        className={`flex items-center justify-center w-9 h-9 rounded-full ${color} text-white border-[3px] border-white shadow-lg`}
        aria-label="icon-button"
        style={{ position: "absolute", top: 10, left: 10 }}
      >
        {icon}
      </button>
    </CustomTooltip>
  );
};

export default IconButton;
