import React, { useEffect, useState } from "react";
import { useGame } from "../../../providers/game";

interface TimerProps {
  startTime?: string;
}

const Timer: React.FC<TimerProps> = ({ startTime }) => {
  const { seconds, setSeconds } = useGame();
  const [initialTime] = useState(startTime ? new Date(startTime).getTime() : Date.now());
  const [, setLastCheckedTime] = useState(Date.now());

  useEffect(() => {
    if (startTime) {
      (async () => {
        const currentTime = Date.now();
        if (currentTime) {
          const elapsedSeconds = Math.floor((currentTime - initialTime) / 1000);
          setSeconds(elapsedSeconds >= 0 ? elapsedSeconds : 0);
        }
      })();
    } else {
      setSeconds(0);
    }

    const updateSeconds = () => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - initialTime) / 1000);
      setSeconds(elapsedSeconds >= 0 ? elapsedSeconds : 0);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        updateSeconds();
        setLastCheckedTime(Date.now());
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    const interval = setInterval(() => {
      updateSeconds();
    }, 1000);

    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [setSeconds, initialTime, startTime]);

  const getDisplayTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (totalSeconds % 60).toString().padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  const getColor = (seconds: number) => {
    if (seconds < 180) return "black"; // 00:00 - 02:59
    if (seconds < 300) return "green"; // 03:00 - 04:59
    if (seconds < 420) return "orange"; // 05:00 - 06:59
    return "red"; // > 07:00
  };

  return (
    <div
      style={{
        width: "80px",
        height: "auto",
        color: getColor(seconds),
        backgroundColor: "white",
        fontWeight: "600",
        fontSize: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px"
      }}
    >
      {getDisplayTime(seconds)}
    </div>
  );
};

export default Timer;
