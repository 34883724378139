import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import NotificationProvider from "./providers/notify";
import { AuthProvider } from "./providers/auth";
import { SocketProvider } from "./providers/socket";
import { GameRoomProvider } from "./providers/room";
import { PreGameProvider } from "./providers/preGame";
import { GameProvider } from "./providers/game";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <NotificationProvider>
        <AuthProvider>
          <SocketProvider>
            <GameRoomProvider>
              <PreGameProvider>
                <GameProvider>
                  <App />
                </GameProvider>
              </PreGameProvider>
            </GameRoomProvider>
          </SocketProvider>
        </AuthProvider>
      </NotificationProvider>
    </React.StrictMode>
  );

  reportWebVitals();
}
