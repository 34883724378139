import React, { useCallback, useEffect, useState } from "react";
import LobbyContent from "./LobbyContent";
import { useGameRoom } from "../../providers/room";
import ConfirmModal from "../../shared/components/Modals/ConfirmModal";
import { CONFIRM_LEAVE_ROOM } from "./utils/LobbyTexts";
import GenericModal from "../../shared/components/Modals/GenericModal";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../providers/socket";
import { MATCH_CHANGES, THEME_RAFFLE } from "../../shared/utils/SocketEvents";
import { usePreGame } from "../../providers/preGame";
import { useGame } from "../../providers/game";
import { GameRoutes } from "../../routes/SystemRoutes";

export default function Lobby(): JSX.Element {
  const { room, isLoadingRoom, leaveRoom, playersIn, playersOut } = useGameRoom();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { resetPreGameData } = usePreGame();
  const { resetGameData } = useGame();

  const modalContent = (
    <ConfirmModal
      handleClose={() => setOpen(false)}
      handleConfirm={() => {}}
      description={CONFIRM_LEAVE_ROOM}
    />
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handleUnload = () => {
      leaveRoom();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [leaveRoom]);

  const handleStartGame = useCallback(() => {
    navigate(GameRoutes.DrawTheme);
  }, [navigate]);

  useEffect(() => {
    if (socket) {
      const handleMatchChanges = (data: any) => {
        const { error } = data;
        if (!error && room?.seed === data.seed) {
          switch (data.type) {
            case THEME_RAFFLE:
              handleStartGame();
              break;
          }
        }
      };

      socket.on(MATCH_CHANGES, handleMatchChanges);

      return () => {
        socket.off(MATCH_CHANGES, handleMatchChanges);
      };
    }
  }, [socket, handleStartGame, room?.seed]);

  useEffect(() => {
    resetPreGameData();
    resetGameData();
  }, [resetGameData, resetPreGameData]);

  return (
    <>
      <LobbyContent
        room={room}
        isLoadingRoom={isLoadingRoom}
        playersIn={playersIn}
        playersOut={playersOut}
        handleStartGame={handleStartGame}
      />
      <GenericModal handleClose={() => setOpen(false)} open={open} children={modalContent} />
    </>
  );
}
