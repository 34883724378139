import React from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/hourglass.json";

const Hourglass = () => {
    return (
      <Lottie
        animationData={animationData}
        loop={true}
      />
    );
  };
  
  export default Hourglass;
  
