import React from "react";
import OTPInput from "react-otp-input";
import "./OtpInput.css";

interface OtpInputProps {
  value: string;
  handleChange: (value: string) => void;
  handlePaste: (e: React.ClipboardEvent<HTMLDivElement>) => void;
}

const OtpInput = ({ value, handleChange, handlePaste }: OtpInputProps) => {
  return (
    <div className="flex flex-row">
      <div className="mr-3">
        <OTPInput
          value={"#"}
          onChange={() => {}}
          onPaste={() => {}}
          numInputs={1}
          shouldAutoFocus
          renderInput={(props) => <input {...props} />}
          containerStyle="otp-container"
          inputStyle="otp-input"
        />
      </div>
      <OTPInput
        value={value.toUpperCase()}
        onChange={(e) => handleChange(e)}
        onPaste={(e) => handlePaste(e)}
        numInputs={6}
        shouldAutoFocus
        renderInput={(props) => <input {...props} />}
        containerStyle="otp-container"
        inputStyle="otp-input"
      />
    </div>
  );
};

export default OtpInput;
