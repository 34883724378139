import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

interface GenericLoaderProps {
  screen?: boolean;
}

function GenericLoader({ screen = false }: GenericLoaderProps): JSX.Element {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return screen ? (
    <Box className="flex flex-row items-center justify-center h-screen">
      <p className="ml-3 text-white text-heading-1 font-semibold">Carregando{dots}</p>
    </Box>
  ) : (
    <p className="p-4 ml-3 text-white text-heading-1 font-semibold">Carregando{dots}</p>
  );
}

export default GenericLoader;
