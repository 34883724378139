const gamePrefix = "/jogo";

export const CommonRoutes = {
  Home: "/",
  GameHome: `${gamePrefix}`,
  NotFound: "*",
  Login: `${gamePrefix}/login`,
  Register: `${gamePrefix}/cadastro`,
  RoomOptions: `${gamePrefix}/opcoes-de-sala`,
  EnterRoom: `${gamePrefix}/entrar-na-sala`,
};

export const GameRoutes = {
  Lobby: `${gamePrefix}/sala-de-espera`,
  MatchResult: `${gamePrefix}/resultados-da-rodada`,
  GameResult: `${gamePrefix}/resultado-do-jogo`,
  DrawTheme: `${gamePrefix}/sortear-tema`,
  DrawProfile: `${gamePrefix}/sortear-perfil`,
  PreGame: `${gamePrefix}/pre-jogo`,
  Deliberation: `${gamePrefix}/deliberacao`,
  Voting: `${gamePrefix}/votacao`,
  WaitingVotes: `${gamePrefix}/contabilizando-votos`,
};