import * as RoomService from "../services/RoomService/RoomService";

export const getRoomPlayers = async (seed: string) => {
  return await RoomService.getRoomPlayers(seed);
};

export const getResultsNumber = async (id: string): Promise<{ numberOfMatches: number }> => {
  const response = await RoomService.getResultsNumber(id);
  return response.data;
};
